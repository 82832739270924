<ng-container *ngIf="notification">
  <div
    class="rs-notice"
    [class.sticky-left]="stickyLeft"
    [class.top-left-right-margin]="topLeftRightMargin"
    [class.bottom-margin]="bottomMargin"
    [class.closing]="isNotificationClosing"
    [class.permanent-info]="notification.inline"
    [class.inline]="notification.inline"
    [class.warning-box]="notification.isWarning && !notification.inline"
    [class.error-box]="notification.isError && !notification.inline"
  >
    <div class="d-flex align-items-start" [ngClass]="{ 'permanent': notification.inline }">
      <div class="warning-icon warning-box d-flex" *ngIf="notification.isWarning || notification.isPermanent">
        <span class="background-dot">
          <span class="ri-exclamation-mark"></span>
        </span>
      </div>
      <div class="error-icon warning-box d-flex" *ngIf="notification.isError">
        <span class="background-dot">
          <span class="ri-exclamation-mark"></span>
        </span>
      </div>

      <!-- Classic Display -> title -->
      <ng-container *ngIf="!notification.inline">
        <div class="d-flex flex-column">
          <div class="d-flex title">
            {{ notification.title }}
          </div>
          <div class="desc">
            <span [innerHTML]="notification.message"></span>
          </div>
          <div class="actions" *ngIf="(isActionButtonVisible || isDocLinkVisible) && !inlineActionButton">
            <a mat-flat-button color="primary" (click)="onNotificationMessageGot()" *ngIf="isActionButtonVisible">
              {{ notification.actionButtonLabel }}
            </a>
            <div class="ml-2" *ngIf="isDocLinkVisible">
              <app-external-link linkType="button" [targetType]="notification.docLinkType">
                <span class="link-content">
                  <span class="rs-left-button-icon ri-external-link"></span>
                  <span class="ml-2">{{ notification.docLinkLabel }}</span>
                </span>
              </app-external-link>
            </div>
          </div>
        </div>

        <div
          class="actions flex-grow-1 justify-content-end"
          *ngIf="(isActionButtonVisible || isDocLinkVisible) && inlineActionButton"
        >
          <a mat-stroked-button color="primary" (click)="onNotificationMessageGot()" *ngIf="isActionButtonVisible">
            {{ notification.actionButtonLabel }}
          </a>
          <div class="ml-2" *ngIf="isDocLinkVisible">
            <app-external-link linkType="button" [targetType]="notification.docLinkType">
              <span class="link-content">
                <span class="rs-left-button-icon ri-external-link"></span>
                <span class="ml-2">{{ notification.docLinkLabel }}</span>
              </span>
            </app-external-link>
          </div>
        </div>
      </ng-container>

      <!-- Inline Display -> message -->
      <ng-container *ngIf="notification.inline">
        <div class="d-flex">
          {{ notification.message }}
        </div>
        <div class="actions" *ngIf="isActionButtonVisible || isDocLinkVisible">
          <a mat-flat-button color="primary" (click)="onNotificationMessageGot()" *ngIf="isActionButtonVisible">
            {{ notification.actionButtonLabel }}
          </a>
          <div class="ml-2" *ngIf="isDocLinkVisible">
            <app-external-link linkType="button" [targetType]="notification.docLinkType">
              <span class="link-content">
                <span class="rs-left-button-icon ri-external-link"></span>
                <span class="ml-2">{{ notification.docLinkLabel }}</span>
              </span>
            </app-external-link>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
