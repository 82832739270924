import type { SceneManager } from '@shared/models';
import type { AbstractMesh, Mesh, Node, TransformNode } from '@babylonjs/core';
import { Vector3, Vector4 } from '@babylonjs/core';
import { environment } from '@env/environment';
import { AppService, RendererService, RocosClientService } from '@shared/services';
import { first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import type { ValueTypes } from '../primitives/primitives';
import { GazeboWorldService } from '../gazebo-world.service';
import { OccupancymapService } from '../occupancymap.service';
import { BabylonRendererService } from '../babylon-renderer.service';
import { RendererModelService } from '../renderer-model.service';
import { RendererSelectionService } from '../renderer-selection.service';
import { boolHasValue, boolHasValueAndTrue, numberHasValue } from '../primitives/validation';
import type { IMesh } from '../primitives/visualizer/interface/IMesh';
import { GuiFactoryService } from './gui-factory.service';
import { RocosSdkClientService } from '@shared/services/rocos-sdk-client';
import { GeoJsonService } from '../geo-json.service';
import { PointCloudImporter } from '../nodes/pointcloud/pointcloud-importer';

export type MeshPropertyType =
  | 'displayName'
  | 'position'
  | 'scaling'
  | 'rotation'
  | 'visibility'
  | 'castsShadow'
  | 'receiveShadows'
  | 'material'
  | 'isPickable'
  | 'renderArea'
  | 'renderRays'
  | 'rayColor'
  | 'rayMaxColor'
  | 'renderPointCloud'
  | 'lineSize'
  | 'pointSize'
  | 'parent'
  | 'transparent'
  | 'options.rootUrl'
  | 'options.bindingType'
  | 'options.sceneFileName'
  | 'options.fileName'
  | 'options.width'
  | 'options.height'
  | 'options.ddPlanId'
  | 'options.ddOverlayId'
  | 'options.layerName'
  | 'options.maximumScreenSpaceError'
  | 'options.pointPairArray'
  | 'options.zoom'
  | 'options.crs'
  | 'options.mapId'
  | 'boundSources.coordinateSpace'
  | 'fileOptions.callsign'
  | 'fileOptions.filename'
  | 'fileOptions.metaFilename'
  | 'geoJSON.mapId'
  | 'geoJSON.labelProperty'
  | 'geoJSON.lineSize'
  | 'geoJSON.pointSize'
  | 'geoJSON.lineMaterial'
  | 'geoJSON.pointMaterial'
  | 'staticGeoJSON.geoJSON'
  | 'staticGeoJSON.flowId'
  | 'controlPointGroup.method'
  | 'controlPoint.id'
  | 'cameraFrustum.fieldOfView'
  | 'cameraFrustum.aspectRatio'
  | 'cameraFrustum.far';

export type MeshValueType = ValueTypes | 'material';

export const meshPropertyValueTypes: Record<MeshPropertyType, MeshValueType> = {
  displayName: 'string',
  position: 'vector3',
  scaling: 'vector3',
  rotation: 'vector3',
  visibility: 'number',
  castsShadow: 'boolean',
  receiveShadows: 'boolean',
  material: 'material',
  isPickable: 'boolean',
  renderArea: 'boolean',
  renderRays: 'boolean',
  rayColor: 'color3',
  rayMaxColor: 'color3',
  renderPointCloud: 'boolean',
  lineSize: 'number',
  pointSize: 'number',
  parent: 'string',
  transparent: 'boolean',

  'options.rootUrl': 'string',
  'options.bindingType': 'string',
  'options.sceneFileName': 'string',
  'options.fileName': 'string',
  'options.width': 'number',
  'options.height': 'number',
  'options.ddPlanId': 'string',
  'options.ddOverlayId': 'string',
  'options.layerName': 'string',
  'options.maximumScreenSpaceError': 'number',
  'options.zoom': 'number',
  'options.crs': 'string',
  'options.pointPairArray': 'pointPairArray',
  'options.mapId': 'string',

  'boundSources.coordinateSpace': 'string',

  'geoJSON.mapId': 'string',
  'geoJSON.labelProperty': 'string',
  'geoJSON.lineSize': 'number',
  'geoJSON.pointSize': 'number',
  'geoJSON.lineMaterial': 'string',
  'geoJSON.pointMaterial': 'string',

  'staticGeoJSON.geoJSON': 'string',
  'staticGeoJSON.flowId': 'string',

  'fileOptions.callsign': 'string',
  'fileOptions.filename': 'string',
  'fileOptions.metaFilename': 'string',

  'controlPointGroup.method': 'string',
  'controlPoint.id': 'string',

  'cameraFrustum.fieldOfView': 'number',
  'cameraFrustum.aspectRatio': 'number',
  'cameraFrustum.far': 'number',
};

const positionProps = ['position', 'scaling', 'rotation'];
const defaultProps = [...positionProps, 'visibility', 'material', 'isPickable'];
const shadowProps = ['castsShadow', 'receiveShadows'];
const defaultPropsWithShadow = [...defaultProps, ...shadowProps];

@Injectable()
export class MeshFactoryService {
  private token: string;

  public get sceneManager(): SceneManager {
    return this.rendererModelService.sceneManager;
  }

  public constructor(
    private gazeboWorldService: GazeboWorldService,
    private occupancymapService: OccupancymapService,
    private babylonRenderer: BabylonRendererService,
    private appService: AppService,
    private rocosClientService: RocosClientService,
    private rendererModelService: RendererModelService,
    private rendererSelectionService: RendererSelectionService,
    private guiFactoryService: GuiFactoryService,
    private sdk: RocosSdkClientService,
    private geoJSONService: GeoJsonService,
    private pointcloudImporter: PointCloudImporter,
  ) {
    this.rocosClientService.token$.subscribe((token) => (this.token = token));
  }

  public applyMeshProp = (meshData: IMesh | any, newMesh: Mesh | TransformNode, meshId: string, prop: string) => {
    const propValue = meshData[prop];
    if (prop === 'visibility' && !numberHasValue(propValue)) return;
    if (prop === 'isPickable' && !boolHasValue(propValue)) return;
    if (newMesh && prop === 'castsShadow' && boolHasValueAndTrue(meshData?.castsShadow)) {
      this.babylonRenderer.addShadowCaster(newMesh);
    }

    if (propValue) this.setMeshValue(meshId, prop, propValue);
  };

  public addMeshToRenderer({
    meshId,
    meshData,
    parentNode,
  }: {
    meshId: string;
    meshData: IMesh | any;
    parentNode?: Node;
  }): void {
    let newMesh: Mesh;

    switch (meshData.type) {
      case 'transform': {
        const transformNode =
          this.babylonRenderer.getTransformNode(meshId) || this.babylonRenderer.createTransformNode(meshId);
        if (!transformNode) return;
        this.updateMesh(parentNode, transformNode, meshData);
        [...positionProps, 'displayName'].forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'geoTransform': {
        const geoTransform = this.babylonRenderer.createOrUpdateGeoTransformNode(
          meshId,
          meshData.options,
          this.rendererModelService.sceneManager.projectLocation,
        );
        this.applyMeshProp(meshData, geoTransform, meshId, 'displayName');
        break;
      }

      case 'lsTransform': {
        const lsTransform = this.babylonRenderer.createOrUpdateLsTransformNode(meshId, meshData.options);
        this.applyMeshProp(meshData, lsTransform, meshId, 'displayName');
        break;
      }

      case 'raster': {
        newMesh = this.babylonRenderer.createMapTiles(
          meshId,
          meshData,
          this.rendererModelService.sceneManager.projectLocation,
          this.appService.projectId,
          this.sdk,
        );
        this.updateMesh(parentNode, newMesh, meshData);
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'tiles': {
        newMesh = this.babylonRenderer.create3dTiles(
          meshId,
          meshData,
          this.rendererModelService.sceneManager.projectLocation,
          this.appService.projectId,
          this.sdk,
        );
        this.updateMesh(parentNode, newMesh, meshData);
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'spot-robot': {
        newMesh = this.babylonRenderer.createSpot(meshId, this.appService.projectId, meshData.callsign, this.sdk);
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'overlays': {
        newMesh = this.babylonRenderer.createOverlayTiles(
          meshId,
          meshData,
          this.rendererModelService.sceneManager.projectLocation,
          this.appService.projectId,
          this.sdk,
        );
        this.updateMesh(parentNode, newMesh, meshData);
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'basemap': {
        newMesh = this.babylonRenderer.createBasemapTiles(
          meshId,
          meshData,
          this.rendererModelService.sceneManager.projectLocation,
          this.appService.projectId,
          this.sdk,
        );
        this.updateMesh(parentNode, newMesh, meshData);
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'locations': {
        newMesh = this.babylonRenderer.createLocations(
          meshId,
          meshData,
          this.rendererModelService.sceneManager.projectLocation,
          this.appService.projectId,
          this.sdk,
          this.guiFactoryService,
        );
        this.updateMesh(parentNode, newMesh, meshData);
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'box': {
        newMesh = this.babylonRenderer.createBox(meshId, meshData.options);
        this.updateMesh(parentNode, newMesh, meshData);
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'sphere': {
        newMesh = this.babylonRenderer.createSphere(meshId, meshData.options);
        this.updateMesh(parentNode, newMesh, meshData);
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'torus': {
        newMesh = this.babylonRenderer.createTorus(meshId, meshData.options);
        this.updateMesh(parentNode, newMesh, meshData);
        newMesh.rotation.x = Math.PI / 2;
        newMesh.bakeCurrentTransformIntoVertices();
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'polyhedron': {
        newMesh = this.babylonRenderer.createPolyhedron(meshId, meshData.options);
        this.updateMesh(parentNode, newMesh, meshData);
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'ground': {
        newMesh = this.babylonRenderer.createGround(meshId, meshData.options);
        this.updateMesh(parentNode, newMesh, meshData);
        newMesh.rotation.x = Math.PI / 2;
        newMesh.bakeCurrentTransformIntoVertices();
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'plane': {
        newMesh = this.babylonRenderer.createPlane(meshId, meshData.options);
        this.updateMesh(parentNode, newMesh, meshData);
        newMesh.rotation.x = Math.PI / 2;
        newMesh.bakeCurrentTransformIntoVertices();
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'geoJSON': {
        newMesh = this.babylonRenderer.createMesh(meshId);
        this.updateMesh(parentNode, newMesh, meshData);
        positionProps.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        this.setMeshValue(meshId, 'visibility', 0);

        if (!meshData.geoJSON?.mapId) break;
        this.geoJSONService.loadAndRenderGeoJson(meshId, meshData);
        break;
      }

      case 'robotMap': {
        const robotMap = this.babylonRenderer.createRobotMap(meshId, meshData);
        this.updateMesh(parentNode, robotMap, meshData);
        robotMap.loadGeoJSON();
        break;
      }

      case 'robotMapLive': {
        const projectId = this.appService.projectId;
        const sourceKey = this.sceneManager.bindingsSourceKey[`${meshData.key}.robotMapLive`];
        const robotGraph = this.babylonRenderer.createRobotGraph(meshId, meshData, projectId, sourceKey);
        this.updateMesh(parentNode, robotGraph, meshData);
        robotGraph.getGraphFromServiceCall();
        break;
      }

      case 'staticGeoJSON': {
        const staticGeoJSON = this.babylonRenderer.createStaticMap(meshId, meshData);
        staticGeoJSON.parent = this.babylonRenderer.getNode(meshData.parent);
        this.updateMesh(parentNode, staticGeoJSON, meshData);
        break;
      }

      case 'laserScan': {
        newMesh = this.babylonRenderer.createDisk(meshId, meshData.options);
        this.updateMesh(parentNode, newMesh, meshData);
        positionProps.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        this.setMeshValue(meshId, 'visibility', 0);
        break;
      }

      case 'line-dashed': {
        const points = [];
        meshData.options.points.forEach((p) => {
          points.push(new Vector3(p[0], p[1], p[2]));
        });

        if (meshData.updatable === undefined || meshData.updatable == null) meshData.updatable = false;
        const newLine = this.babylonRenderer.createDashLines(meshId, {
          points,
          updatable: meshData.updatable,
        });
        newLine.color = this.babylonRenderer.getColor3(meshData.color);
        newMesh = newLine;
        this.updateMesh(parentNode, newMesh, meshData);
        break;
      }

      case 'line': {
        const points = [];
        meshData.options.points.forEach((p) => {
          points.push(new Vector3(p[0], p[1], p[2]));
        });

        if (meshData.updatable === undefined || meshData.updatable == null) meshData.updatable = false;
        const newLine = this.babylonRenderer.createLines(meshId, {
          points,
          updatable: meshData.updatable,
        });
        newLine.color = this.babylonRenderer.getColor3(meshData.color);
        newMesh = newLine;
        this.updateMesh(parentNode, newMesh, meshData);
        break;
      }

      case 'voxels': {
        newMesh = this.babylonRenderer.createVoxels(meshId);
        this.updateMesh(parentNode, newMesh, meshData);
        [...positionProps, 'visibility', 'material', ...shadowProps].forEach((prop) =>
          this.applyMeshProp(meshData, newMesh, meshId, prop),
        );
        break;
      }

      case 'pointcloud': {
        if (meshData.options?.bindingType === 'upload' && meshData.options?.sceneFileName) {
          newMesh = this.babylonRenderer.createBox(
            meshId,
            meshData.boundingBoxOptions || {
              size: 10,
            },
          );
          this.updateMesh(parentNode, newMesh, meshData);
          newMesh.visibility = numberHasValue(meshData.boundingBoxLoadingVisibility)
            ? meshData.boundingBoxLoadingVisibility
            : 0.5;
        } else {
          newMesh = this.babylonRenderer.createSphere(meshId, meshData.options);
          this.updateMesh(parentNode, newMesh, meshData);
          newMesh.rotation.x = Math.PI / 2;
          newMesh.bakeCurrentTransformIntoVertices();
        }

        defaultProps.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        if (meshData.options?.bindingType === 'upload' && meshData.options?.sceneFileName) {
          this.pointcloudImporter
            .importPLYPointcloud(this.appService.projectId, meshData?.options?.sceneFileName)
            .then((vertices) => {
              let maxCapOfPointClouds = 1;
              if (meshData?.maxNumOfInstances) maxCapOfPointClouds = meshData.maxNumOfInstances;
              this.babylonRenderer
                .renderStaticPointcloud(newMesh.id, RendererService.pointCloudSuffix, vertices, maxCapOfPointClouds)
                .then((pointCloud) => {
                  pointCloud.mesh.parent = newMesh;
                });
            });
        }
        break;
      }

      case 'file-any': {
        const containerMesh: Mesh = this.babylonRenderer.createBox(
          meshId,
          meshData.boundingBoxOptions || {
            size: 10,
          },
        );
        if (parentNode && containerMesh) containerMesh.parent = parentNode;
        [...positionProps, 'castsShadow', 'receiveShadows', 'isPickable'].forEach((prop) =>
          this.applyMeshProp(meshData, newMesh, meshId, prop),
        );
        if (boolHasValue(meshData.castsShadow)) this.babylonRenderer.addShadowCaster(containerMesh);
        if (meshData.ops && !meshData.ops.visible && newMesh) newMesh.setEnabled(false);

        containerMesh.visibility = numberHasValue(meshData.boundingBoxLoadingVisibility)
          ? meshData.boundingBoxLoadingVisibility
          : 0.5;

        const onSuccessCallback = (callbackMeshId) => {
          this.setParentForObject(callbackMeshId);
          if (callbackMeshId === this.rendererSelectionService.activeObject$.getValue()?.key)
            this.rendererSelectionService.selectHighlightedMeshes(meshId, true);
        };

        // if the resource is on an API served, authorization token needs to be added to the http headers
        if (meshData?.options?.rootUrl?.indexOf(environment.api.url) >= 0) {
          this.babylonRenderer.importFromFile(meshId, meshData, containerMesh, onSuccessCallback, this.token);
        } else {
          this.babylonRenderer.importFromFile(meshId, meshData, containerMesh, onSuccessCallback);
        }
        newMesh = containerMesh;
        if (meshData.ops && !meshData.ops.visible && newMesh) newMesh.setEnabled(false);
        break;
      }

      case 'gazebo-world': {
        const createGeneralMeshForGazeboWorld = () => {
          const containerMesh: Mesh = this.babylonRenderer.createBox(
            meshId,
            meshData.boundingBoxOptions || {
              size: 1,
            },
          );
          if (parentNode && containerMesh) containerMesh.parent = parentNode;
          [...positionProps, 'castsShadow', 'receiveShadows', 'isPickable'].forEach((prop) =>
            this.applyMeshProp(meshData, newMesh, meshId, prop),
          );
          if (boolHasValueAndTrue(meshData.castsShadow)) this.babylonRenderer.addShadowCaster(containerMesh);
          containerMesh.visibility = numberHasValue(meshData.boundingBoxLoadingVisibility)
            ? meshData.boundingBoxLoadingVisibility
            : 0.5;
          return containerMesh;
        };

        // Two ways to load the world:
        // 1) Load from path
        if (meshData.options?.rootUrl && meshData.options.fileName) {
          const loadGazeboWorldFileFromPath = (loadPath: string) => {
            return this.rocosClientService.rocosClient.http.get(loadPath).pipe(
              first(),
              map((result) => {
                let xmlString = '';
                if (result) xmlString = result.data;
                return xmlString;
              }),
            );
          };

          const path = `${meshData.options.rootUrl}${meshData.options.fileName}`;
          loadGazeboWorldFileFromPath(path).subscribe((xml: string) => {
            newMesh = createGeneralMeshForGazeboWorld();
            this.gazeboWorldService.loadWorld(xml, meshId, meshData.material);
            newMesh.visibility = numberHasValue(meshData.boundingBoxLoadedVisibility)
              ? meshData.boundingBoxLoadedVisibility
              : 0.5;
          });

          // 2) Load from XML
        } else if (meshData.options?.xml) {
          const xml = meshData.options.xml;
          newMesh = createGeneralMeshForGazeboWorld();
          newMesh.visibility = numberHasValue(meshData.boundingBoxLoadedVisibility)
            ? meshData.boundingBoxLoadedVisibility
            : 0.5;

          this.gazeboWorldService.loadWorld(xml, meshId, meshData.material);
        } else {
          console.warn('Could not load Gazebo World XML as correct options were not provided.');
        }
        break;
      }

      case 'occupancyMap': {
        newMesh = this.babylonRenderer.createBox(meshId, meshData.options);
        this.updateMesh(parentNode, newMesh, meshData);
        if (meshData.fileOptions?.callsign && meshData.fileOptions.filename && meshData.fileOptions.metaFilename) {
          this.occupancymapService
            .getImageDataFromFile(
              this.appService.projectId,
              meshData.fileOptions.callsign,
              meshData.fileOptions.filename,
              meshData.fileOptions.metaFilename,
            )
            .subscribe((imageInfo) => {
              // perhaps it should return an object
              // with properties imgData, width, height, resolution and position (from yaml file)
              // probably will be an observable that then calls this when loaded
              this.babylonRenderer.renderOccupancyMap(
                imageInfo.imageData,
                imageInfo.width,
                imageInfo.height,
                imageInfo.resolution,
                imageInfo.position,
                meshId,
              );
            });
        }
        defaultProps.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'controlPointGroup': {
        const node = this.babylonRenderer.createMesh(meshId);
        this.updateMesh(parentNode, node, meshData);
        this.setMeshValue(meshId, 'visibility', 0);
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }

      case 'controlPoint': {
        const faceUV = new Array(6).fill(new Vector4(0, 0, 0, 0));
        faceUV[2] = new Vector4(0, 0, 1, 1);
        newMesh = this.babylonRenderer.createBox(meshId, { ...meshData.options, faceUV });
        this.updateMesh(parentNode, newMesh, meshData);
        defaultPropsWithShadow.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));

        const parentMeshData = this.sceneManager.findMesh(meshData.parent);
        const guiVisible = meshData.ops?.visible && parentMeshData?.ops?.visible;
        this.guiFactoryService.addGuiLabel(`${meshData.controlPoint.id}`, newMesh, meshId, guiVisible);

        break;
      }

      case 'cameraFrustum': {
        newMesh = this.babylonRenderer.createFrustum(meshId, meshData.cameraFrustum);
        this.updateMesh(parentNode, newMesh, meshData);
        positionProps.forEach((prop) => this.applyMeshProp(meshData, newMesh, meshId, prop));
        break;
      }
    }
  }

  /**
   * Sets the value in the property of both the storage system (sceneManager)
   * as well as the live rendered object on screen (babylonRenderer.scene)
   */
  public setMeshValue(meshId: string, property: string, newValue: any) {
    const foundMesh = this.sceneManager.findMesh(meshId);
    if (!foundMesh) return;
    const babylonRendererMesh = this.babylonRenderer.getMesh(meshId) || this.babylonRenderer.getTransformNode(meshId);
    const valueType: MeshValueType = meshPropertyValueTypes[property];
    switch (valueType) {
      case 'string': {
        if (property.includes('.')) {
          this.assignByPropertyString(foundMesh, property, newValue);
          this.babylonRenderer.deleteMesh(meshId);
          this.addMeshToRenderer({ meshId, meshData: foundMesh });
          if (foundMesh.type === 'file-any') this.resetParentForObject(foundMesh.key);
          this.rendererSelectionService.resetGizmoManager();
        } else if (property === 'parent') {
          if (!babylonRendererMesh) break;
          let hasChanged = false;
          if (!newValue) {
            const oldPosition = babylonRendererMesh.getAbsolutePosition();
            const oldRotation = babylonRendererMesh.absoluteRotationQuaternion;
            const oldScaling = babylonRendererMesh.absoluteScaling;
            babylonRendererMesh.parent = null;
            foundMesh.parent = undefined;
            babylonRendererMesh.setAbsolutePosition(oldPosition);
            babylonRendererMesh.rotation = oldRotation.toEulerAngles();
            babylonRendererMesh.scaling = oldScaling;
            this.sceneManager.updateMeshesKeyArray();
            this.rendererSelectionService.resetGizmoManager();
            hasChanged = true;
          } else if (newValue !== meshId) {
            let babylonParentMesh: TransformNode | Mesh = this.babylonRenderer.getMesh(newValue);
            if (!babylonParentMesh) babylonParentMesh = this.babylonRenderer.getTransformNode(newValue);

            if (babylonParentMesh && newValue !== meshId) {
              babylonRendererMesh.computeWorldMatrix(true);
              babylonRendererMesh.parent = babylonParentMesh;
              foundMesh.parent = newValue;
              this.sceneManager.updateMeshesKeyArray();
              this.rendererSelectionService.resetGizmoManager();
              hasChanged = true;
            }
          }

          if (hasChanged) {
            foundMesh.position = [
              babylonRendererMesh.position.x,
              babylonRendererMesh.position.y,
              babylonRendererMesh.position.z,
            ];
            foundMesh.rotation = [
              babylonRendererMesh.rotation.x,
              babylonRendererMesh.rotation.y,
              babylonRendererMesh.rotation.z,
            ];
            foundMesh.scaling = [
              babylonRendererMesh.scaling.x,
              babylonRendererMesh.scaling.y,
              babylonRendererMesh.scaling.z,
            ];
          }
        } else {
          foundMesh[property] = newValue;
          if (babylonRendererMesh) babylonRendererMesh[property] = newValue;
        }

        break;
      }
      case 'number': {
        if (property === 'options.width' || property === 'options.height') {
          const propName = property.replace('options.', '');
          foundMesh.options[propName] = newValue;
          this.babylonRenderer.deleteMesh(meshId);
          this.addMeshToRenderer({ meshId, meshData: foundMesh });
          this.rendererSelectionService.resetGizmoManager();
        } else if (property.includes('.')) {
          this.assignByPropertyString(foundMesh, property, newValue);
          this.babylonRenderer.deleteMesh(meshId);
          this.addMeshToRenderer({ meshId, meshData: foundMesh });
          this.rendererSelectionService.resetGizmoManager();
        } else {
          if (!babylonRendererMesh) break;
          foundMesh[property] = newValue;
          babylonRendererMesh[property] = newValue;
        }
        break;
      }
      case 'vector3': {
        if (!babylonRendererMesh) break;

        let arrayValue = [0, 0, 0];
        if (newValue && newValue !== '') arrayValue = newValue.toString().split(',');

        foundMesh[property] = arrayValue;
        if (arrayValue?.length !== 3) {
          arrayValue = [arrayValue?.[0] || 0, arrayValue?.[1] || 0, 0];
        }
        babylonRendererMesh[property] = this.babylonRenderer.getVector(arrayValue.map((item) => item || 0));

        break;
      }
      case 'color3': {
        foundMesh[property] = newValue;
        if (babylonRendererMesh) babylonRendererMesh[property] = this.babylonRenderer.getColor3(newValue);
        break;
      }
      case 'color4': {
        foundMesh[property] = newValue;
        if (babylonRendererMesh) babylonRendererMesh[property] = this.babylonRenderer.getColor4(newValue);
        break;
      }
      case 'material': {
        foundMesh[property] = newValue;
        if (babylonRendererMesh) babylonRendererMesh[property] = this.babylonRenderer.getMaterial(newValue);
        break;
      }
      case 'boolean': {
        foundMesh[property] = newValue;
        if (babylonRendererMesh) babylonRendererMesh[property] = JSON.parse(newValue);
        break;
      }
      case 'pointPairArray': {
        this.assignByPropertyString(foundMesh, property, newValue);
        this.addMeshToRenderer({ meshId, meshData: foundMesh });
        break;
      }
      default: {
        break;
      }
    }
  }

  // this method does not do resampling
  public setParentForObject(parentId?: string): void {
    Object.keys(this.sceneManager.getMeshes()).forEach((key) => {
      const meshData = this.sceneManager.findMesh(key);
      if (!meshData.parent) return;

      // Update everything OR if parentId is provided;
      // only update meshes with this parentId as parent. 🤦
      if (parentId && meshData.parent !== parentId) return;

      let parentMesh: AbstractMesh | TransformNode = this.babylonRenderer.getMesh(meshData.parent);
      if (!parentMesh) parentMesh = this.babylonRenderer.getTransformNode(meshData.parent);

      let currentMesh: AbstractMesh | TransformNode = this.babylonRenderer.getMesh(meshData.key);
      if (!currentMesh) currentMesh = this.babylonRenderer.getTransformNode(meshData.key);

      if (currentMesh && parentMesh && currentMesh?.parent?.id !== parentMesh?.id) {
        currentMesh.parent = parentMesh;
      }
    });
  }

  private resetParentForObject(objectId: string): void {
    Object.keys(this.sceneManager.getMeshes()).forEach((mesh) => {
      const foundMesh = this.sceneManager.findMesh(mesh);
      if (foundMesh?.parent === objectId) {
        const internalMesh = this.babylonRenderer.getMesh(foundMesh.key);
        if (internalMesh) internalMesh.parent = null;
      }
    });
  }

  private updateMesh(parentNode: Node, newMesh: Mesh | TransformNode, meshData: IMesh) {
    if (parentNode && newMesh) newMesh.parent = parentNode;
    this.updateEnabledOnMesh(newMesh, meshData);
  }

  private updateEnabledOnMesh(newMesh: Node, meshData: IMesh) {
    if (meshData.ops && !meshData.ops.visible && newMesh) newMesh.setEnabled(false);
  }

  private assignByPropertyString(mesh: IMesh, property: string, newValue: string | number | boolean): IMesh {
    const keys = property.split('.');
    let current = mesh;
    keys.forEach((prop, i) => {
      if (i === keys.length - 1) {
        current[prop] = newValue;
        return;
      }
      current = current[prop] || (current[prop] = {});
    });
    return current;
  }
}
