<ng-container *transloco="let tCommon; read: 'common'">
  <form [formGroup]="form">
    <div class="robot-select-container">
      <!-- Optional Robot Status -->
      <ng-container *ngIf="includeStatus">
        <div class="icon-container-and-status" [ngClass]="{ 'alive': robotHeartbeat?.isConnected }">
          <div class="icon-container">
            <ng-container *ngIf="isLoading">
              <app-loading-spinner [isLoading]="true"></app-loading-spinner>
            </ng-container>

            <ng-container *ngIf="!isLoading">
              <span class="icon ri-robots"></span>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- Legacy appearance is necessary to disable label floating -->
      <!-- See doc: https://material.angular.io/components/form-field/api#FloatLabelType -->
      <mat-form-field appearance="legacy" floatLabel="never">
        <!-- Robot Selection -->
        <mat-select
          [formControl]="callsign"
          [placeholder]="placeholder"
          [matTooltip]="(robots$ | async)?.['length'] > 0 ? '' : notFoundTooltip"
          (selectionChange)="onOptionSelected($event.value)"
        >
          <mat-option *ngFor="let robot of robots$ | async" [value]="robot.callsign">
            <span
              >{{ robot.name }} (<span>{{ robot.callsign }}</span
              >)</span
            >
          </mat-option>
        </mat-select>

        <mat-error *ngIf="callsign?.errors?.['required']">{{ tCommon('requiredLabel') }}</mat-error>
        <mat-hint *ngIf="robotHeartbeat || hint">{{ robotHeartbeat ? robotHeartbeat.textStatus : hint }}</mat-hint>
      </mat-form-field>
    </div>
  </form>
</ng-container>
