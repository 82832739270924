import { Injectable } from '@angular/core';
import type { PLYElement } from '../../utils/pointcloud-ply-utils';
import type { AbstractMesh, LinesMesh, Node } from '@babylonjs/core';
import {
  Animation,
  ArcRotateCamera,
  BoundingInfo,
  Camera,
  Color3,
  Color4,
  CubeTexture,
  CubicEase,
  DirectionalLight,
  DynamicTexture,
  EasingFunction,
  Engine,
  GizmoManager,
  GlowLayer,
  HemisphericLight,
  Mesh,
  MeshBuilder,
  PointerEventTypes,
  PointLight,
  PointsCloudSystem,
  PolygonMeshBuilder,
  Scene,
  ShaderMaterial,
  ShadowGenerator,
  SolidParticleSystem,
  SpotLight,
  StandardMaterial,
  Texture,
  TransformNode,
  UtilityLayerRenderer,
  Vector2,
  Vector3,
} from '@babylonjs/core';
import * as earcut from 'earcut';
import type { PointcloudService } from './pointcloud.service';
import type { LaserscanService } from './laserscan.service';
import type { CameraFrustum, IMesh } from './primitives/visualizer/interface/IMesh';
import { meshKeyDecode, meshKeyEncode } from '../../helpers/meshKey';
import { BehaviorSubject, Subject } from 'rxjs';
import { Utils } from '../../utils';
import { defaultMeshOps } from './primitives/objectPrimitives';
import { evaluateToNumber } from '../../utils/evaluate-to-number';
import { GuiFactoryService } from './factories/gui-factory.service';
import { getFrustumMaterial, POLYGON_EDGES_COLOR } from './primitives/gizmos/gizmo-materials';
import { GridMaterial, ShadowOnlyMaterial } from '@babylonjs/materials';
import type { GpsLocation } from './transform';
import {
  orthoProjString,
  pointFromFrame,
  pointToFrame,
  updateGeoTransformNode,
  updateLeastSquaresTransformNode,
} from './transform';
import { RobotMap } from './nodes/robot-map';
import { RobotMapsService } from '@robot/shared/robot-maps.service';
import { Tiles3D } from './nodes/tiles3D';
import type { RocosSdkClientService } from '../rocos-sdk-client';
import { StaticGeoJSON } from './nodes/static-geojson';
import { BasemapTiles2D, MapTiles2D, OverlayTiles2D } from './nodes/tiles2D';
import { Spot } from './nodes/spot';
import { RendererModelService } from './renderer-model.service';
import { Voxels } from './nodes/voxels';
import { importMesh } from './utils/importMesh';
import { RobotGraph } from './nodes/robot-graph';
import '@babylonjs/loaders/glTF';
import { Locations } from './nodes/locations';

/**
 * BABYLON JS normal mode is the following
 *        Y (Up / Altitude)   in ROS Z is up
 *        ^
 *        |
 *        |
 *        |
 *        |-------------> X (East)
 *       /
 *      /
 *     /
 *    v
 *   Z (South)
 *
 *  Babylon is put into "Right Hand" mode in order for positive rotation to be anticlockwise, same as ROS
 *
 *
 *  Expected ROS Input format is https://www.ros.org/reps/rep-0103.html (East, North, Up)
 *
 *        Z (World Altitude | Object's Up, in ROS Z is up)
 *        ^
 *        |    ^ Y (World North | Object's Left)
 *        |   /
 *        |  /
 *        | /
 *         --------------> X (World East, Object's Forwards)
 *
 *  This system expects ROS as input where UP position is Z
 *  (Babylon environment has been rotated 90 degrees around X axis in order to compensate for original orientation)
 *
 */

@Injectable()
export class BabylonRendererService {
  public engine: Engine;
  public scene: Scene;

  public _scene$ = new Subject<Scene>();
  public get scene$() {
    return this._scene$.asObservable();
  }

  public rendererUtilityLayer: UtilityLayerRenderer;
  public rendererGizmoManager: GizmoManager;

  public particleSystems: {
    [meshId: string]: SolidParticleSystem[];
  } = {}; // stores a list of point clouds being rendered as particle systems
  public pointclouds: {
    [meshId: string]: PointsCloudSystem[];
  } = {}; // stores a list of point clouds being rendered as point cloud systems
  public laserScans: any[] = []; // stores the list of lines within a specific laserScan id
  public occupancyMaps: any[] = [];

  public changeStatus$ = new BehaviorSubject<boolean>(false);

  // @deprecated use scene$ instead. https://dronedeploy.atlassian.net/browse/GRP-1270
  private _isReady$ = new BehaviorSubject<boolean>(false);
  public get isReady$() {
    return this._isReady$.asObservable();
  }

  private shadowGenerators: ShadowGenerator[] = [];
  private cameraPointerObserver; // watches the mouse in the camera of the babylonRenderer
  private canvasElement;
  private sceneObjectPrefix = 'rocos-';
  private lastCameraRadius: number;

  constructor(
    private guiFactoryService: GuiFactoryService,
    private robotMapsService: RobotMapsService,
    private rendererModelService: RendererModelService,
  ) {}

  public setup(canvasElement) {
    this.canvasElement = canvasElement;
    this.reset();
    this.engine = new Engine(this.canvasElement, true, {
      preserveDrawingBuffer: true,
      stencil: true,
      useHighPrecisionMatrix: true,
    });

    this.scene = new Scene(this.engine);
    this.scene.useRightHandedSystem = true;
    this.guiFactoryService.setup();

    const gl = new GlowLayer('glow', this.scene);
    gl.intensity = 0.3;

    this.scene.executeWhenReady(() => {
      this.rendererUtilityLayer = new UtilityLayerRenderer(this.scene);
      this.rendererUtilityLayer.utilityLayerScene.autoClearDepthAndStencil = false;
      this.rendererGizmoManager = new GizmoManager(this.scene);
      this.rendererGizmoManager.usePointerToAttachGizmos = false;
      this._isReady$.next(true);
      this._scene$.next(this.scene);
    });

    const hdrTexture = CubeTexture.CreateFromPrefilteredData(
      'https://content.rocos.io/3dmodels/environment.dds',
      this.scene,
    );
    hdrTexture.gammaSpace = false;
    this.scene.environmentTexture = hdrTexture;

    // Set pan sensibility by camera radius
    this.scene.beforeRender = () => {
      const camera = this.scene.activeCamera as ArcRotateCamera;
      if (!camera) return;
      if (camera.name !== 'rocos-perspective') return;
      if (!this.lastCameraRadius) this.lastCameraRadius = camera.radius;
      if (this.lastCameraRadius === camera.radius) return;
      const ratio = this.lastCameraRadius / camera.radius;
      this.lastCameraRadius = camera.radius;
      camera.panningSensibility *= ratio;
    };
  }

  public cleanUp() {
    if (this.rendererGizmoManager) this.rendererGizmoManager.dispose();
    this.rendererGizmoManager = null;
    if (this.rendererUtilityLayer) this.rendererUtilityLayer.dispose();
    this.rendererUtilityLayer = null;
    if (this.scene) {
      this.scene.meshes.forEach((m) => {
        m.dispose();
      });
      this.scene.dispose();
    }

    if (this.engine) this.engine.dispose();

    this.scene = null;
    this.engine = null;

    delete this.scene;
    delete this.engine;

    if (this.laserScans) this.laserScans = [];
    if (this.occupancyMaps) this.occupancyMaps = [];

    this.resetPointClouds();
  }

  public resetPointCloud(meshId: string) {
    if (!this.particleSystems?.[meshId]) return;

    const list = this.particleSystems[meshId];
    if (list?.length > 0) {
      list.forEach((item) => {
        item.dispose();
      });
    }

    delete this.particleSystems[meshId];
  }

  public createSkybox(skyboxData) {
    const skybox = MeshBuilder.CreateBox('skybox-box', { size: 1000 }, this.scene);
    skybox.isPickable = false;
    const skyboxMaterial = new StandardMaterial('skybox-material', this.scene);
    skyboxMaterial.backFaceCulling = false;
    skyboxMaterial.reflectionTexture = new CubeTexture(skyboxData.fileUrlPrefix, this.scene);
    skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    skyboxMaterial.disableLighting = true;
    skybox.material = skyboxMaterial;
    skybox.rotation.x = Math.PI / 2;
  }

  public getKeyFromFullId(keyWithPrefix: string) {
    return keyWithPrefix.replace(this.sceneObjectPrefix, '');
  }

  public isRocosMesh(key: string) {
    return key.indexOf(this.sceneObjectPrefix) === 0;
  }

  public getBaseKey(mesh: AbstractMesh): string {
    let meshKey = this.getKeyFromFullId(mesh.id);
    if (!this.isRocosMesh(mesh.id)) {
      // get top level parent
      let count = 0;
      let parent = mesh.parent;
      let lastParent = parent;
      // prevent infinite loop
      while (parent && count < 20) {
        count++;
        lastParent = parent;
        parent = parent.parent;
      }
      if (lastParent) meshKey = meshKeyEncode([this.getKeyFromFullId(lastParent.id), mesh.id]);
    }
    return meshKey;
  }

  public createMesh(key) {
    return new Mesh(this.sceneObjectPrefix + key, this.scene);
  }

  public createSphere(key, options) {
    return MeshBuilder.CreateSphere(this.sceneObjectPrefix + key, options, this.scene);
  }

  public createBox(key, options) {
    return MeshBuilder.CreateBox(this.sceneObjectPrefix + key, options, this.scene);
  }

  public createVoxels(key: string): Voxels {
    return new Voxels(this.sceneObjectPrefix + key, this.scene);
  }

  public createOrUpdateGeoTransformNode(key, options, gpsLocation): TransformNode {
    let transformNode = this.getTransformNode(key);
    if (!transformNode) transformNode = this.createTransformNode(key);
    if (!options.crs) {
      options.crs = orthoProjString(gpsLocation);
    }
    updateGeoTransformNode(transformNode, gpsLocation, options.crs);
    return transformNode;
  }

  public createOrUpdateLsTransformNode(key, options): TransformNode {
    let transformNode = this.getTransformNode(key);
    if (!transformNode) transformNode = this.createTransformNode(key);
    if (!options.pointPairArray) {
      return transformNode;
    }
    updateLeastSquaresTransformNode(transformNode, options.pointPairArray);
    return transformNode;
  }

  public createMapTiles(
    key: string,
    meshData: IMesh,
    projectLocation: GpsLocation,
    projectId: string,
    sdk: RocosSdkClientService,
  ) {
    return new MapTiles2D(this.sceneObjectPrefix + key, this.scene, meshData, projectLocation, projectId, sdk);
  }

  public createOverlayTiles(
    key: string,
    meshData: IMesh,
    projectLocation: GpsLocation,
    projectId: string,
    sdk: RocosSdkClientService,
  ) {
    return new OverlayTiles2D(this.sceneObjectPrefix + key, this.scene, meshData, projectLocation, projectId, sdk);
  }

  public createBasemapTiles(
    key: string,
    meshData: IMesh,
    projectLocation: GpsLocation,
    projectId: string,
    sdk: RocosSdkClientService,
  ) {
    return new BasemapTiles2D(this.sceneObjectPrefix + key, this.scene, meshData, projectLocation, projectId, sdk);
  }

  public create3dTiles(
    key: string,
    meshData: IMesh,
    projectLocation: GpsLocation,
    projectId: string,
    sdk: RocosSdkClientService,
  ) {
    return new Tiles3D(this.sceneObjectPrefix + key, this.scene, meshData, projectLocation, projectId, sdk);
  }

  public createLocations(
    key: string,
    meshData: IMesh,
    projectLocation: GpsLocation,
    projectId: string,
    sdk: RocosSdkClientService,
    guiFactoryService: GuiFactoryService,
  ) {
    return new Locations(
      this.sceneObjectPrefix + key,
      this.scene,
      meshData,
      projectLocation,
      projectId,
      sdk,
      guiFactoryService,
    );
  }

  public createSpot(key: string, projectId: string, callsign: string, sdk: RocosSdkClientService) {
    return new Spot(this.sceneObjectPrefix + key, projectId, callsign, this.scene, sdk);
  }

  public createFrustum(key: string, cameraFrustum: CameraFrustum): Mesh {
    const scale = 0.2;
    const fov = Math.max(0.01, Math.min(cameraFrustum?.fieldOfView ?? 45, 120));
    const aspect = Math.max(0.01, cameraFrustum?.aspectRatio ?? 1.778);
    const far = Math.max(0.1, cameraFrustum?.far ?? 3);
    const near = 0.0000001;
    const angle = 135 - fov;
    const halfFov = (far * Math.sin(fov * (Math.PI / 180))) / Math.sin(angle * (Math.PI / 180));
    const fovWidth = halfFov * 2 * scale;
    const height = fovWidth / aspect;

    const shape = [
      new Vector3(height, -fovWidth, 0),
      new Vector3(height, fovWidth, 0),
      new Vector3(-height, fovWidth, 0),
      new Vector3(-height, -fovWidth, 0),
    ];
    const path = [new Vector3(far, 0, 0), new Vector3(near, 0, 0)];

    const scaling = (index: number, distance: number) => {
      switch (index) {
        case 0:
          return 1;
        case 1:
          return 1 - distance / far;
        default:
          return 1;
      }
    };

    const mesh = MeshBuilder.ExtrudeShapeCustom(
      this.sceneObjectPrefix + key,
      {
        shape,
        closeShape: true,
        path,
        scaleFunction: scaling,
        sideOrientation: Mesh.DOUBLESIDE,
      },
      this.scene,
    );
    mesh.material = getFrustumMaterial({ scene: this.scene });
    mesh.enableEdgesRendering();
    mesh.edgesWidth = 2.0;
    mesh.edgesColor = POLYGON_EDGES_COLOR;

    return mesh;
  }

  public createPolyhedron(key, options) {
    return MeshBuilder.CreatePolyhedron(this.sceneObjectPrefix + key, options, this.scene);
  }

  public createTransformNode(key) {
    return new TransformNode(this.sceneObjectPrefix + key, this.scene);
  }

  public createTorus(key, options) {
    return MeshBuilder.CreateTorus(this.sceneObjectPrefix + key, options, this.scene);
  }

  public createCylinder(key: string, options): Mesh {
    return MeshBuilder.CreateCylinder(this.sceneObjectPrefix + key, options, this.scene);
  }

  public createDisk(key, options) {
    return MeshBuilder.CreateDisc(this.sceneObjectPrefix + key, options, this.scene);
  }

  public createPlane(key, options) {
    return MeshBuilder.CreatePlane(this.sceneObjectPrefix + key, options, this.scene);
  }

  public createGround(key, options) {
    return MeshBuilder.CreateGround(this.sceneObjectPrefix + key, options, this.scene);
  }

  public createLines(key, options) {
    return MeshBuilder.CreateLines(this.sceneObjectPrefix + key, options, this.scene);
  }

  public createTube(key, options) {
    return MeshBuilder.CreateTube(this.sceneObjectPrefix + key, options, this.scene);
  }

  public createDashLines(key, options) {
    return MeshBuilder.CreateDashedLines(this.sceneObjectPrefix + key, options, this.scene);
  }

  public createMaterial(key) {
    return new StandardMaterial(this.sceneObjectPrefix + key, this.scene);
  }

  public createGridMaterial(key) {
    return new GridMaterial(this.sceneObjectPrefix + key, this.scene);
  }

  public createShadowOnlyMaterial(key) {
    return new ShadowOnlyMaterial(this.sceneObjectPrefix + key, this.scene);
  }

  public createCustomShaderMaterial(key, commonShaderName) {
    return new ShaderMaterial(this.sceneObjectPrefix + key, this.scene, commonShaderName, {
      needAlphaBlending: false,
      needAlphaTesting: false,
      // needDepthBlending: true,
      attributes: [
        'position',
        'color',
        'normal',
        'uv',
        'world0',
        'world1',
        'world2',
        'world3',
        'pointcolor',
        'counts',
        'octreeDepth',
      ],
      defines: [],
      samplers: [],
      uniforms: [
        'world',
        'viewProjection',
        'worldView',
        'worldViewProjection',
        'view',
        'projection',
        'time',
        'resolution',
        'camPos',
        'camTarget',
        'colorMix',
        'billboard',
        'confidenceThreshold',
        'paraboloidPoint',
        'paraboloidWeight',
        'pointScale',
        'roundCorners',
        'visType',
        'maxDepth',
      ],
    });
  }

  public createDirectionalLight(key, direction) {
    return new DirectionalLight(this.sceneObjectPrefix + key, this.getVector(direction), this.scene);
  }

  public createHemisphericLight(key, direction) {
    return new HemisphericLight(this.sceneObjectPrefix + key, direction, this.scene);
  }

  public createPointLight(key, position) {
    return new PointLight(this.sceneObjectPrefix + key, position, this.scene);
  }

  public createSpotLight(key, position: Vector3, direction: Vector3, angle, exponent) {
    return new SpotLight(this.sceneObjectPrefix + key, position, direction, angle, exponent, this.scene);
  }

  public createRobotMap(key: string, meshData: IMesh) {
    return new RobotMap(this.sceneObjectPrefix + key, this.scene, meshData, this.robotMapsService);
  }

  public createRobotGraph(key: string, meshData: IMesh, projectId: string, sourceKey: string) {
    return new RobotGraph(
      this.sceneObjectPrefix + key,
      this.scene,
      this.robotMapsService,
      projectId,
      sourceKey,
      Boolean(meshData?.debug),
    );
  }

  public createStaticMap(key: string, meshData: IMesh) {
    return new StaticGeoJSON(
      this.sceneObjectPrefix + key,
      this.scene,
      meshData,
      this.rendererModelService.sceneManager.projectLocation,
      this.guiFactoryService,
    );
  }

  public getLight(key) {
    return this.scene.getLightById(this.sceneObjectPrefix + key);
  }

  public getMesh(key: string) {
    const pathKey = meshKeyDecode(key);
    if (pathKey.length < 2) {
      return this.scene.getMeshById(this.sceneObjectPrefix + key);
    } else if (this.isRocosMesh(pathKey[1]) && pathKey[1].indexOf('coord') > 0) {
      // a hack to get the transform node
      const node = this.scene.getTransformNodeById(pathKey[1])?.getChildMeshes(true)?.[0];
      if (!node) this.scene.getMeshById(this.sceneObjectPrefix + pathKey[0]);
      return undefined;
    } else {
      return this.getMeshByPath(pathKey);
    }
  }

  public getMeshByPath(path: string[]) {
    let mesh = this.scene.getMeshById(this.sceneObjectPrefix + path[0]);
    let i = 1;
    while (i < path.length) {
      const key = path[i];
      const meshesFound = mesh?.getChildMeshes(false, (node) => node.id === key);
      // Assign the first found mesh to be traversed next
      // TODO(matej): implement recursion in case there are multiple meshes with same id on the same level
      if (meshesFound?.length) {
        mesh = meshesFound[0];
      }
      i++;
    }
    return mesh;
  }

  public getMaterial(key) {
    return this.scene.getMaterialById(this.sceneObjectPrefix + key);
  }

  public removeMaterial(key: string): void {
    const material = this.getMaterial(key);
    if (material) {
      this.scene.removeMaterial(material);
    }
  }

  public getCamera(key) {
    return this.scene.getCameraById(this.sceneObjectPrefix + key);
  }

  public getNode(key: string): Node {
    return this.scene.getNodeById(this.sceneObjectPrefix + key);
  }

  public getTransformNode(key: string): TransformNode {
    return this.scene.getTransformNodeById(this.sceneObjectPrefix + key);
  }

  public getTransformNodeOrMeshByDisplayName(displayName: string): TransformNode | Mesh {
    const transformNodes = this.scene.transformNodes.filter((node) => node['displayName'] === displayName);
    if (transformNodes.length) return transformNodes[0];
    const meshes = this.scene.meshes.filter((mesh) => mesh['displayName'] === displayName);
    if (meshes.length) return meshes[0];
    return null;
  }

  public getLocations(): Locations[] {
    const locationsMeshes = this.scene.meshes.filter((mesh) => mesh instanceof Locations) as Locations[];
    if (!locationsMeshes) return [];
    return locationsMeshes[0].getChildMeshes(true).sort((a, b) => a.name.localeCompare(b.name)) as Locations[];
  }

  public pointToFrame(frame: string, point: Vector3): Vector3 {
    if (!frame) return point;
    try {
      const parent: TransformNode | Mesh | string = this.getTransformNodeOrMeshByDisplayName(frame) || frame;
      return pointToFrame(this.rendererModelService.sceneManager.projectLocation, parent, point);
    } catch (e) {
      console.error(e);
      return point;
    }
  }

  public pointFromFrame(frame: string, point: Vector3): Vector3 {
    if (!frame) return point;
    try {
      const parent: TransformNode | Mesh | string = this.getTransformNodeOrMeshByDisplayName(frame) || frame;
      return pointFromFrame(this.rendererModelService.sceneManager.projectLocation, parent, point);
    } catch (e) {
      console.error(e);
      return point;
    }
  }

  public setActiveCamera(key, cameraData) {
    if (this.cameraPointerObserver) {
      this.scene.onPointerObservable.remove(this.cameraPointerObserver);
      this.cameraPointerObserver = null;
    }

    this.scene.setActiveCameraById(this.sceneObjectPrefix + key);

    if (cameraData.type === 'orthographic') {
      let zoom = cameraData.zoom;
      let mouseXStart = 0;
      let mouseYStart = 0;
      let camXStart = 0;
      let camYStart = 0;
      let mouseDown = false;
      let evt: any;

      const cam = this.scene.activeCamera as ArcRotateCamera;
      cam.minZ = 1;

      this.cameraPointerObserver = this.scene.onPointerObservable.add((pointerInfo) => {
        switch (pointerInfo.type) {
          case PointerEventTypes.POINTERDOWN:
            mouseDown = true;
            mouseXStart = this.scene.pointerX; // mouse x is right
            mouseYStart = this.scene.pointerY; // mouse y is down
            camXStart = cam.position.x;
            camYStart = cam.position.y;
            break;

          case PointerEventTypes.POINTERUP:
            mouseDown = false;
            break;

          case PointerEventTypes.POINTERMOVE:
            if (mouseDown) {
              const offsetX = mouseXStart - this.scene.pointerX;
              const offsetY = mouseYStart - this.scene.pointerY;
              cam.position.x = camXStart + offsetX / (500 / zoom);
              cam.position.y = camYStart - offsetY / (500 / zoom);
              cam.target.x = camXStart + offsetX / (500 / zoom);
              cam.target.y = camYStart - offsetY / (500 / zoom);
            }
            break;

          case PointerEventTypes.POINTERWHEEL:
            evt = pointerInfo.event;
            if (evt.deltaY > 0) {
              zoom = zoom + zoom / 5;
              this.setOrthographicCameraPerspective(key, zoom);
            } else if (evt.deltaY < 0) {
              zoom = zoom - zoom / 5;
              this.setOrthographicCameraPerspective(key, zoom);
            }
            break;
        }
      });
    }
  }

  public getBoundingBoxExcludingEmptyParent(mesh: AbstractMesh) {
    let min = new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
    let max = new Vector3(Number.MIN_VALUE, Number.MIN_VALUE, Number.MIN_VALUE);

    mesh.refreshBoundingInfo();
    const boundingBoxInfo = mesh.getBoundingInfo();
    if (boundingBoxInfo.diagonalLength > 0) {
      min = boundingBoxInfo.boundingBox.minimumWorld;
      max = boundingBoxInfo.boundingBox.maximumWorld;
    }

    mesh.getChildMeshes().forEach((childMesh) => {
      childMesh.computeWorldMatrix(true);
      childMesh.refreshBoundingInfo();
      const childMin = childMesh.getBoundingInfo().boundingBox.minimumWorld;
      const childMax = childMesh.getBoundingInfo().boundingBox.maximumWorld;
      min = Vector3.Minimize(min, childMin);
      max = Vector3.Maximize(max, childMax);
    });

    return new BoundingInfo(min, max);
  }

  public focusCameraOnMesh = (meshID: string) => {
    const camera = this.scene.activeCamera as ArcRotateCamera;
    if (!meshID) return;
    const babylonMesh = this.getMesh(meshID);
    if (!babylonMesh) return;
    const ease = new CubicEase();
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
    const newTarget = this.getBoundingBoxExcludingEmptyParent(babylonMesh).boundingBox.centerWorld;
    Animation.CreateAndStartAnimation('at5', camera, 'target', 50, 120, camera.target, newTarget, 0, ease);
    camera.update();
  };

  public deleteLight(objectKey) {
    let babylonRendererLight = this.getLight(objectKey);
    this.scene.removeLight(babylonRendererLight);
    babylonRendererLight.dispose(false);
    babylonRendererLight = null;
  }

  public deleteCamera(objectKey) {
    let babylonRendererCamera = this.getCamera(objectKey);
    this.scene.removeCamera(babylonRendererCamera);
    babylonRendererCamera.dispose(false);
    babylonRendererCamera = null;
  }

  /** Deletes the mesh from the 3D Environment */
  public deleteMesh(objectKey) {
    let babylonRendererMesh = this.getMesh(objectKey);
    if (!babylonRendererMesh) return;
    if (this.occupancyMaps) this.occupancyMaps[objectKey] = false;
    this.scene.removeMesh(babylonRendererMesh);
    babylonRendererMesh.dispose(false, false);
    babylonRendererMesh = null;
  }

  public deleteTransformNode(objectKey) {
    let object = this.getTransformNode(objectKey);
    if (!object) return;

    this.scene.removeTransformNode(object);
    object.dispose(false);
    object = null;
  }

  /**
   * Traverses the property hierarchy based on the Bindings existing propertyId
   *
   * @param propertyId Property to change
   * @param mesh  @babylonjs/core Mesh
   * @param finalValue The object to be assigned to the mesh's property
   */
  public applyValueToMesh(propertyId, mesh: AbstractMesh, finalValue: any): boolean {
    // Get the property Hierarchy Array (e.g. position.x would land up being ['position', 'x'] with length of 2)
    try {
      const propArray = propertyId.split('.');

      if (propArray.length === 1) {
        mesh[propArray[0]] = finalValue;
      } else if (propArray.length === 2) {
        mesh[propArray[0]][propArray[1]] = finalValue;
      } else if (propArray.length === 3) {
        mesh[propArray[0]][propArray[1]][propArray[2]] = finalValue;
      } else if (propArray.length === 4) {
        mesh[propArray[0]][propArray[1]][propArray[2]][propArray[3]] = finalValue;
      } else {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  }

  public importFromFile(
    masterMeshId: string,
    meshData: IMesh,
    containerMesh: Mesh,
    onSuccessCallback?: (meshId) => void,
    authorizationToken?: string,
  ): void {
    if (!meshData?.options?.rootUrl || !meshData?.options?.sceneFileName) return;

    importMesh(
      { meshId: masterMeshId, meshData, containerMesh, onSuccessCallback, authorizationToken },
      this.scene,
    ).then((msg) => {
      this.onImportFromFileSuccess(
        msg.meshId,
        msg.meshData,
        msg.containerMesh,
        msg.meshes,
        msg.transformNodes,
        msg.onSuccessCallback,
      );
    });
  }

  public addCameraToScene(key, camData) {
    switch (camData.type) {
      case 'arcrotate': {
        const newCamera = new ArcRotateCamera(
          this.sceneObjectPrefix + key,
          Math.PI / 2,
          Math.PI / 2,
          camData.radius,
          this.getVector(camData.target),
          this.scene,
        );
        newCamera.upVector = new Vector3(0, 0, 1);
        newCamera.position = this.getVector(camData.position);
        newCamera.attachControl(this.canvasElement, true);

        if (camData.wheelPrecision) newCamera.wheelPrecision = camData.wheelPrecision;
        if (camData.lowerBetaLimit) newCamera.lowerBetaLimit = camData.lowerBetaLimit;
        if (camData.upperBetaLimit) newCamera.upperBetaLimit = camData.upperBetaLimit;
        if (camData.lowerRadiusLimit) newCamera.lowerRadiusLimit = camData.lowerRadiusLimit;
        if (camData.upperRadiusLimit) newCamera.upperRadiusLimit = camData.upperRadiusLimit;
        break;
      }
      case 'arcfollow': {
        const targetMesh = this.scene.getMeshByID(this.sceneObjectPrefix + camData.targetId);

        if (targetMesh) {
          const newCamera = new ArcRotateCamera(
            this.sceneObjectPrefix + key,
            0,
            Math.PI / 4,
            camData.radius,
            this.getVector(camData.target),
            this.scene,
          );

          if (camData.useAutoRotationBehavior) newCamera.useAutoRotationBehavior = camData.useAutoRotationBehavior;
          if (camData.wheelPrecision) newCamera.wheelPrecision = camData.wheelPrecision;
          if (camData.lowerBetaLimit) newCamera.lowerBetaLimit = camData.lowerBetaLimit;
          if (camData.upperBetaLimit) newCamera.upperBetaLimit = camData.upperBetaLimit;
          if (camData.lowerRadiusLimit) newCamera.lowerRadiusLimit = camData.lowerRadiusLimit;
          if (camData.upperRadiusLimit) newCamera.upperRadiusLimit = camData.upperRadiusLimit;

          newCamera.lockedTarget = targetMesh;
          newCamera.upVector = new Vector3(0, 0, 1);
          newCamera.attachControl(this.canvasElement, true);
        }

        break;
      }
      case 'orthographic': {
        // Positions it straight above the target position (alpha and beta of 0)
        const newCamera = new ArcRotateCamera(
          this.sceneObjectPrefix + key,
          Math.PI / 2,
          0,
          camData.radius || 30,
          this.getVector(camData.target),
          this.scene,
        );

        newCamera.upVector = new Vector3(0, 0, 1);
        newCamera.mode = Camera.ORTHOGRAPHIC_CAMERA;
        this.setOrthographicCameraPerspective(key, camData.orthoTop);

        break;
      }
      default: {
        break;
      }
    }
  }

  public addShadowGenerator(newLight, shadowDarkness) {
    const shadowGenerator = new ShadowGenerator(512, newLight);
    shadowGenerator.setDarkness(shadowDarkness); // todo could make this a setting on the light
    shadowGenerator.useBlurExponentialShadowMap = true;
    this.shadowGenerators.push(shadowGenerator);
  }

  public addShadowCaster(newObject) {
    this.shadowGenerators.forEach((sg) => {
      sg.addShadowCaster(newObject, true);
    });
  }

  public removeShadowCaster(object) {
    this.shadowGenerators.forEach((sg) => {
      sg.removeShadowCaster(object, true);
    });
  }

  public setOrthographicCameraPerspective(cameraId, zoom) {
    const sceneCam = this.scene.getCameraById(this.sceneObjectPrefix + cameraId);
    const ratio = this.canvasElement.width / this.canvasElement.height;
    const newWidth = zoom * ratio;
    sceneCam.orthoTop = zoom;
    sceneCam.orthoLeft = -Math.abs(newWidth);
    sceneCam.orthoRight = newWidth;
    sceneCam.orthoBottom = -Math.abs(zoom);
  }

  /**
   * Renders the canvas image of an occupancy map onto a mesh
   *
   * @param canvasImageData     The image data from a canvas to be rendered
   * @param width               Width in pixels of the image
   * @param height              Height in pixels of the image
   * @param resolution          Resolution of meters per pixel (e.g. 0.05 = 0.05m per pixel)
   * @param position            The position of the corner of the occupancy grid in
   *                            relation to map coordinate system (e.g. {x:20, y:1, z:0)
   * @param parentMeshId        Id of the mesh that should contain the new mesh with the
   *                            rendered occupancy map, this parent mesh will be hidden
   */
  public renderOccupancyMap(canvasImageData, width, height, resolution, position, parentMeshId) {
    const meshKey = parentMeshId + '-occupancymap';
    const materialKey = parentMeshId + '-occupancymap-material';
    const textureKey = parentMeshId + '-occupancymap-texture';
    const groundWidth = width * resolution;
    const groundHeight = height * resolution;
    let renderToMesh;
    let renderMaterial;
    let occMapTexture;

    const mapKey = `${canvasImageData.data.length}::${width}::${height}::${resolution}`;
    const alreadyRendered = this.occupancyMaps[parentMeshId] && this.occupancyMaps[parentMeshId] === mapKey;
    // check if this occupancy map scaffolding has already been created or not
    if (!alreadyRendered) {
      this.occupancyMaps[parentMeshId] = mapKey;
      renderToMesh = this.createBox(meshKey, {
        width: groundWidth,
        height: groundHeight,
        depth: 0.01,
      });
      const parentMesh = this.getMesh(parentMeshId);

      parentMesh.getChildMeshes(false).forEach((x) => {
        parentMesh.removeChild(x);
        x.material.dispose();
        x.dispose();
      });

      if (parentMesh) {
        renderToMesh.parent = parentMesh;
        parentMesh.isVisible = false;
      }

      renderMaterial = this.createMaterial(materialKey);
      renderMaterial.diffuseColor = new Color3(1, 1, 1);
      renderMaterial.specularColor = new Color3(0, 0, 0);
      renderMaterial.emissiveColor = new Color3(0, 0, 0);
      renderMaterial.ambientColor = new Color3(1, 1, 1);

      occMapTexture = new DynamicTexture(
        textureKey,
        { width, height },
        this.scene,
        false,
        Texture.NEAREST_SAMPLINGMODE,
      );
      renderMaterial.diffuseTexture = occMapTexture;
      renderMaterial.diffuseTexture.hasAlpha = true;

      renderToMesh.material = renderMaterial;
    } else {
      renderToMesh = this.getMesh(meshKey);
      renderMaterial = this.getMaterial(materialKey);
      occMapTexture = renderMaterial.diffuseTexture;
    }

    renderToMesh.position = new Vector3(position.x + groundWidth / 2, position.y + groundHeight / 2, -0.1);
    const tx = occMapTexture.getContext();
    tx.putImageData(canvasImageData, 0, 0);

    occMapTexture.update(true);
  }

  public renderLaserScanRays(
    masterMeshId,
    laserScanRaysSuffix,
    laserScanservice: LaserscanService,
    laserScanProperties,
    position,
    rotation,
    _scene,
  ) {
    if (!this.laserScans[masterMeshId]) {
      this.laserScans[masterMeshId] = []; // array of lines
    }

    const raysParentId = masterMeshId + laserScanRaysSuffix;

    let raysParent;
    raysParent = this.getNode(raysParentId);
    if (!raysParent) {
      raysParent = this.createTransformNode(raysParentId);
      raysParent.parent = this.getNode(masterMeshId);
    }

    raysParent.position = position;
    raysParent.rotation = rotation;

    let rayIndex = 0;
    laserScanservice.rays.forEach((ray) => {
      let line: LinesMesh;

      if (!this.laserScans[masterMeshId][rayIndex]) {
        // ray never been rendered
        // generate the line for the first time
        line = MeshBuilder.CreateLines(
          this.sceneObjectPrefix + masterMeshId + '_r' + rayIndex,
          {
            points: [new Vector3(0, 0, 0), new Vector3(5, 0, rayIndex)],
            updatable: true,
          },
          this.scene,
        );

        line.parent = raysParent;
      } else {
        line = this.laserScans[masterMeshId][rayIndex];
      }

      if (ray !== null) {
        const points = [];
        points.push(ray.startPoint);
        points.push(ray.endPoint);
        line = MeshBuilder.CreateLines(
          this.sceneObjectPrefix + masterMeshId + '_r' + rayIndex,
          {
            points,
            updatable: true,
            instance: line, // just update the existing instance of the line
          },
          this.scene,
        );

        if (!ray.maxRange) {
          if (laserScanProperties.rayColor) {
            line.color = this.getColor3(laserScanProperties.rayColor);
          } else {
            line.color = this.getColor3('#00FF00');
          }
        } else {
          if (laserScanProperties.rayMaxColor) {
            line.color = this.getColor3(laserScanProperties.rayMaxColor);
          } else {
            line.color = this.getColor3('#CCCCCC');
          }
        }
      } else {
        line.visibility = 0;
      }

      this.laserScans[masterMeshId][rayIndex] = line;
      rayIndex++;
    });
  }

  public renderLaserScanRayArea(
    masterMeshId,
    meshIdSuffix,
    laserScanservice: LaserscanService,
    laserScanProperties,
    position,
    rotation,
    scene,
  ) {
    const raysParentId = masterMeshId + meshIdSuffix;
    let raysParent;
    raysParent = this.getNode(raysParentId);
    if (!raysParent) {
      raysParent = this.createTransformNode(raysParentId);
      raysParent.parent = this.getNode(masterMeshId);
    }

    raysParent.position = position;
    raysParent.rotation = rotation;

    const corners = [];
    laserScanservice.rays.forEach((ray) => {
      if (ray) {
        const point2D = new Vector2(ray.endPoint.x, ray.endPoint.y);
        corners.push(point2D);
      }
    });

    if (this.laserScans[masterMeshId + meshIdSuffix]) {
      // remove the old mesh
      this.laserScans[masterMeshId + meshIdSuffix].dispose();
    }

    const poly_tri = new PolygonMeshBuilder('polytri', corners, scene, earcut);

    let laserAreaMaterial;
    laserAreaMaterial = this.getMaterial('rocos-laserarea');

    if (!laserAreaMaterial) {
      laserAreaMaterial = this.createMaterial('rocos-laserarea');
      laserAreaMaterial.diffuseColor = new Color3(0, 1, 0);
      laserAreaMaterial.specularColor = new Color3(0, 0, 0);
      laserAreaMaterial.emissiveColor = new Color3(0, 1, 0);
      laserAreaMaterial.ambientColor = new Color3(0, 1, 0);
    }

    const polygon = poly_tri.build(null, 0.05);
    polygon.rotation.x = -Math.PI / 2;
    polygon.material = laserAreaMaterial;
    polygon.position.z = 0.001;
    polygon.parent = raysParent;

    this.laserScans[masterMeshId + meshIdSuffix] = polygon; // array of lines
  }

  public renderStaticPointcloud(
    masterMeshId: string,
    suffix: string,
    vertices: PLYElement[],
    maxInstances: number,
  ): Promise<PointsCloudSystem> {
    const pointCloudId = masterMeshId + suffix;

    // check to see if this pointcloud id already exists
    if (this.pointclouds[masterMeshId] && this.pointclouds[masterMeshId].length >= maxInstances) {
      const needToBeRemoved = this.pointclouds[masterMeshId].length + 1 - maxInstances;
      // Destroy Pointcloud
      if (this.pointclouds?.[masterMeshId]) {
        const list = this.pointclouds[masterMeshId];
        for (let i = 0; i < needToBeRemoved && i < list.length; i++) {
          const item = list[i];
          item.dispose();
        }
        this.pointclouds[masterMeshId].splice(0, needToBeRemoved);
      }
    }

    // Create new point cloud
    const pointCloud = new PointsCloudSystem(this.sceneObjectPrefix + pointCloudId, 3, this.scene);

    pointCloud.addPoints(vertices.length, (particle, i, _s) => {
      pointCloud.particles[i].position.x = vertices[i].x;
      pointCloud.particles[i].position.y = vertices[i].y;
      pointCloud.particles[i].position.z = vertices[i].z;
      pointCloud.particles[i].color = Color4.FromInts(
        vertices[i].red || 0,
        vertices[i].green || 0,
        vertices[i].blue || 0,
        vertices[i].alpha || 255,
      );
    });

    pointCloud.computeParticleColor = false;
    pointCloud.computeParticleTexture = false;

    return pointCloud.buildMeshAsync().then(() => {
      pointCloud.mesh.parent = this.getNode(masterMeshId);
      if (!this.pointclouds[masterMeshId]) {
        this.pointclouds[masterMeshId] = [];
      }
      // Insert this new one to list.
      this.pointclouds[masterMeshId].push(pointCloud);
      return pointCloud;
    });
  }

  public renderPointCloud(
    masterMeshId,
    laserScanPointsSuffix,
    pointCloudservice: PointcloudService,
    position,
    rotation,
    materialKey,
    maxNumOfInstances: number,
  ): void {
    const pointCloudId = masterMeshId + laserScanPointsSuffix;
    const createNewPointCloud = () => {
      // Create new point cloud
      const point = MeshBuilder.CreateDisc('p', { tessellation: 3, radius: 0.0005 }, this.scene);
      const pointCloud = new SolidParticleSystem(this.sceneObjectPrefix + pointCloudId, this.scene);
      pointCloud.addShape(point, pointCloudservice.numPoints);
      const mesh = pointCloud.buildMesh();
      if (materialKey != null) mesh.material = this.getMaterial(materialKey);
      point.dispose();
      pointCloud.initParticles = () => {
        for (let p = 0; p < pointCloud.nbParticles; p++) {
          try {
            if (pointCloudservice.coordArray[p]) {
              pointCloud.particles[p].position.x = pointCloudservice.coordArray[p][0];
              pointCloud.particles[p].position.y = pointCloudservice.coordArray[p][1];
              pointCloud.particles[p].position.z = pointCloudservice.coordArray[p][2];
              pointCloud.particles[p].isVisible = true;
            } else {
              // coord null so hide it
              pointCloud.particles[p].isVisible = false;
            }
          } catch (error) {
            // coordArray sometimes wasn't there as the stream never finished,
            // is now caught above, this catches any other exceptions
          }
        }
      };

      pointCloud.initParticles();
      pointCloud.computeParticleColor = false;
      pointCloud.computeParticleTexture = false;
      pointCloud.setParticles();
      pointCloud.mesh.parent = this.getNode(masterMeshId);

      if (!this.particleSystems[masterMeshId]) {
        this.particleSystems[masterMeshId] = [];
      }
      // Insert this new one to list.
      this.particleSystems[masterMeshId].push(pointCloud);
    };

    const destroyPointCloud = (meshId: string, index: number = 0, count: number = 0) => {
      if (this.particleSystems?.[meshId]) {
        const list = this.particleSystems[meshId];
        for (let i = index; i < count && i < list.length; i++) {
          const item = list[i];
          item.dispose();
        }
        this.particleSystems[meshId].splice(index, count);
      }
    };

    const updateAllPointCloudsPosition = () => {
      if (this.particleSystems?.[masterMeshId]) {
        const items = this.particleSystems[masterMeshId];
        if (items?.length > 0) {
          items.forEach((item) => {
            if (item?.mesh) {
              item.mesh.position = position;
              item.mesh.rotation = rotation;
            }
          });
        }
      }
    };

    // check to see if this pointcloud id already exists
    if (this.particleSystems[masterMeshId] && this.particleSystems[masterMeshId].length >= maxNumOfInstances) {
      const needToBeRemoved = this.particleSystems[masterMeshId].length + 1 - maxNumOfInstances;
      destroyPointCloud(masterMeshId, 0, needToBeRemoved);
    }

    createNewPointCloud();
    updateAllPointCloudsPosition();
  }

  /** Color can be [255, 255, 0, 255] or [255, 255, 0] or '#FF0000' or '#FF0000FF'
   *  It ignores alpha channel setting and sets to no transparency
   */
  public getColor3(color: any): Color3 {
    if (color.toString().match(/,/g) && color.toString().match(/,/g).length === 3) {
      // RGBA
      return new Color3(color[0] / 255, color[1] / 255, color[2] / 255);
    } else if (color.toString().match(/,/g) && color.toString().match(/,/g).length === 2) {
      // RGB
      return new Color3(color[0] / 255, color[1] / 255, color[2] / 255);
    } else if (color.toString().toLowerCase().startsWith('#') && color.length === 9) {
      // HEX with alpha
      return Color3.FromHexString(color.toString().substring(0, 7).toUpperCase());
    } else if (color.toString().toLowerCase().startsWith('#') && color.length === 7) {
      // HEX no alpha
      return Color3.FromHexString(color.toString().toUpperCase());
    } else {
      // error parsing color, set it to white
      return new Color3(1, 1, 1);
    }
  }

  /** Color can be [255, 255, 0, 255] or [255, 255, 0] or '#FF0000' or '#FF0000FF'
   *  Includes alpha channel, if none provided then sets to no transparency
   */
  public getColor4(color: any): Color4 {
    if (color.toString().match(/,/g) && color.toString().match(/,/g).length === 3) {
      // RGBA
      return new Color4(color[0] / 255, color[1] / 255, color[2] / 255, color[3] / 255);
    } else if (color.toString().match(/,/g) && color.toString().match(/,/g).length === 2) {
      // RGB
      return new Color4(color[0] / 255, color[1] / 255, color[2] / 255, 1);
    } else if (color.toString().toLowerCase().startsWith('#') && color.length === 9) {
      // HEX with alpha
      return Color4.FromHexString(color.toUpperCase());
    } else if (color.toString().toLowerCase().startsWith('#') && color.length === 7) {
      // HEX no alpha
      return Color4.FromHexString(color.toUpperCase() + 'FF');
    } else {
      // error parsing color, set it to white
      return new Color4(1, 1, 1, 1);
    }
  }

  /** can take an array of float or string (of floats)
   *  e.g. [1, 0, 0] or ["1", "0", "0"]  or ["Math.PI", "-Math.PI", "Math.PI/2"] 🤦
   *  Expects [x, y, z] (right hand rule with z for up) https://www.ros.org/reps/rep-0103.html
   */
  public getVector(vectorArrayXYZ: any[]): Vector3 {
    if (!vectorArrayXYZ) return null;
    const x = evaluateToNumber(vectorArrayXYZ[0]);
    const y = evaluateToNumber(vectorArrayXYZ[1]);
    const z = evaluateToNumber(vectorArrayXYZ[2]);
    return new Vector3(x, y, z);
  }

  private reset() {
    if (this.scene) this.scene.dispose();
    if (this.engine) this.engine.dispose();
    if (this.laserScans) this.laserScans = [];
    if (this.occupancyMaps) this.occupancyMaps = [];
    this.resetPointClouds();
  }

  private resetPointClouds() {
    if (this.particleSystems) {
      Object.keys(this.particleSystems).forEach((key) => {
        const list = this.particleSystems[key];

        if (list?.length > 0) {
          list.forEach((item) => {
            item.dispose();
          });
        }
      });

      this.particleSystems = {};
    }
  }

  private onImportFromFileSuccess(
    meshId: string,
    meshData: IMesh,
    containerMesh: Mesh,
    newMeshes: AbstractMesh[],
    transformNodes: TransformNode[],
    onSuccessCallback?: (meshId) => void,
  ) {
    // nodes is optional on IMesh but must be an array
    meshData.nodes = meshData.nodes || [];

    // process children of a 3D object, if they don't exist yet
    const beforeNodes: string[] = meshData.nodes.map((item: IMesh) => item.key) || [];
    const afterNodes: string[] = [];

    if (newMeshes?.length || transformNodes?.length) {
      const coordNodeId = meshId + 'coord';
      const coordMesh = this.createTransformNode(coordNodeId);
      coordMesh.parent = this.getNode(meshId);
      coordMesh.parent = containerMesh;
      coordMesh.rotation.x = Math.PI / 2;
      coordMesh.name = 'coord';
      afterNodes.push(this.createChildMesh(coordMesh, meshData)?.key);

      // bind the top level objects to the hidden mesh
      newMeshes.forEach((mesh) => {
        if (mesh.parent === null) mesh.parent = coordMesh;
        if (meshData.visibility !== undefined && meshData.visibility != null) mesh.visibility = meshData.visibility;
        if (meshData.material) mesh.material = this.scene.getMaterialByID(this.sceneObjectPrefix + meshData.material);
        if (meshData.castsShadow !== undefined && meshData.castsShadow != null && mesh) this.addShadowCaster(mesh);
        afterNodes.push(this.createChildMesh(mesh, meshData)?.key);
      });
      transformNodes.forEach((mesh) => {
        if (mesh.parent === null) mesh.parent = coordMesh;
        afterNodes.push(this.createChildMesh(mesh, meshData)?.key);
      });
    }

    if (meshData.boundingBoxLoadedVisibility !== undefined && meshData.boundingBoxLoadedVisibility != null) {
      containerMesh.visibility =
        typeof meshData.boundingBoxLoadedVisibility === 'string'
          ? parseFloat(meshData.boundingBoxLoadedVisibility)
          : meshData.boundingBoxLoadedVisibility;
    } else {
      containerMesh.visibility = 0;
    }

    // apply setting to container mesh after render
    if (meshData?.position) containerMesh.position = this.getVector(meshData.position);
    if (meshData?.rotation) containerMesh.rotation = this.getVector(meshData.rotation);
    if (meshData?.scaling) containerMesh.scaling = this.getVector(meshData.scaling);
    if (onSuccessCallback) onSuccessCallback(meshId);

    const nodeChanges = Utils.getArrayDifference(beforeNodes, afterNodes);
    if (nodeChanges.toRemove.length > 0) {
      meshData.nodes = meshData.nodes?.filter((v: IMesh) => nodeChanges.toRemove.indexOf(v.key) === -1);
    }
    if (nodeChanges.toRemove.length > 0 || nodeChanges.toAdd.length > 0) {
      this.changeStatus$.next(true);
    }
  }

  private createChildMesh(mesh: AbstractMesh | TransformNode, meshData: IMesh): IMesh {
    const coordMeshKey = meshKeyEncode([meshData.key, mesh.id]);
    let foundCoordMesh = meshData.nodes?.find((item) => item.key === coordMeshKey);
    if (!foundCoordMesh) {
      const meshParentId = mesh?.parent?.id ? this.getKeyFromFullId(mesh.parent.id) : undefined;
      foundCoordMesh = {
        type: 'file-any-child',
        key: coordMeshKey,
        displayName: mesh.name ?? mesh.id,
        isPickable: false,
        ops: { ...defaultMeshOps },
        parent: meshParentId !== meshData.key ? meshKeyEncode([meshData.key, mesh?.parent?.id]) : meshData.key,
        position: [mesh.position.x, mesh.position.y, mesh.position.z],
        rotation: [mesh.rotation.x, mesh.rotation.y, mesh.rotation.z],
        scaling: [mesh.scaling.x, mesh.scaling.y, mesh.scaling.z],
      } as IMesh;
      if (mesh.rotationQuaternion) {
        const eulerRotation = mesh.rotationQuaternion.toEulerAngles();
        foundCoordMesh.rotation = [eulerRotation.x, eulerRotation.y, eulerRotation.z];
      }
      meshData.nodes.push(foundCoordMesh);
    } else {
      if (foundCoordMesh?.parent) {
        const keyDecoded = meshKeyDecode(foundCoordMesh.parent);
        let parentKey: string;
        if (keyDecoded.length > 1) {
          parentKey = keyDecoded[keyDecoded.length - 1];
        } else {
          parentKey = this.sceneObjectPrefix + keyDecoded[0];
        }
        if (!mesh.parent || mesh.parent.id !== parentKey) {
          const foundParent = this.getMesh(foundCoordMesh.parent);
          if (foundParent) mesh.parent = foundParent;
        }
      }

      if (foundCoordMesh?.position) mesh.position = this.getVector(foundCoordMesh.position);
      if (foundCoordMesh?.rotation) mesh.rotation = this.getVector(foundCoordMesh.rotation);
      if (foundCoordMesh?.scaling) mesh.scaling = this.getVector(foundCoordMesh.scaling);

      return foundCoordMesh;
    }
    return undefined;
  }
}
