import {
  Axis,
  Color3,
  Color4,
  Mesh,
  MeshBuilder,
  PolygonMeshBuilder,
  type Scene,
  Space,
  StandardMaterial,
  Vector2,
  Vector3,
} from '@babylonjs/core';
import type { PrimitiveMetaData } from '../primitives/primitives';
import type { IMesh } from '../primitives/visualizer/interface/IMesh';
import type { GpsLocation } from '../transform';
import { crsToWorld } from '../transform';
import * as earcut from 'earcut';
import type { RocosSdkClientService } from '@shared/services';
import type { Location } from '@dronedeploy/rocos-js-sdk';
import type { GuiFactoryService } from '../factories/gui-factory.service';

const Z_OFFSET = 0.1;
export const DEFAULT_OPACITY = 0.2;
export const SELECTED_OPACITY = 0.6;

export const locationsMetadata: PrimitiveMetaData = {
  key: 'locations',
  label: 'DroneDeploy Locations',
  icon: 'ri-3d-box',
  editorPanels: {
    properties: {
      name: true,
      position: false,
      rotation: false,
      scaling: false,
      material: false,
    },
    parent: false,
    bindPosition: false,
    bindRotationEuler: false,
    bindRotationQuaternion: false,
    bindAdvanced: false,
  },
};

export class Locations extends Mesh {
  constructor(
    name: string,
    scene: Scene,
    protected meshData: IMesh,
    protected projectLocation: GpsLocation,
    protected projectId: string,
    protected sdk: RocosSdkClientService,
    protected guiFactoryService: GuiFactoryService,
  ) {
    super(name, scene);
    this.getLocations().catch((err) => {
      console.error(err);
    });
  }

  public override dispose(doNotRecurse?: boolean, disposeMaterialAndTextures?: boolean) {
    this.guiFactoryService.removeAllGuiControlsOfParentName(this.id);
    super.dispose(doNotRecurse, disposeMaterialAndTextures);
  }

  async getLocations(): Promise<void> {
    const response = await this.sdk.client.getIntegrationService().getLocations(this.projectId);
    this.renderLocations(response.results);
  }

  private renderLocations(locations: Location[]): void {
    locations.forEach((location) => {
      switch (location.shape.shapeType) {
        case 'RECTANGLE':
        case 'POLYGON':
          this.createPolygon(location);
          break;
        case 'CIRCLE':
          this.createCircle(location);
          break;
        default:
          console.warn('Unknown location shape type');
          break;
      }
    });
  }

  private getWorldCoordinates(location: Location): Vector2[] {
    return location.shape.coordinates.map((coord) => {
      const pointWGS84 = new Vector3(coord.lng, coord.lat, 0);
      const pointWorld = crsToWorld(this.projectLocation, 'WGS84', pointWGS84);
      return new Vector2(pointWorld.x, pointWorld.y);
    });
  }

  private setPropertiesFromLocation(location: Location, mesh: Mesh) {
    const material = new StandardMaterial(location.id, this.getScene());
    const shapeStyle = location.shapeStyle;
    const fillColor = shapeStyle.fillColor || '#FFFFFF';
    const color = Color3.FromHexString(fillColor);
    material.diffuseColor = color;
    material.ambientColor = color;
    material.emissiveColor = color;
    material.alpha = DEFAULT_OPACITY;
    material.backFaceCulling = false;
    mesh.material = material;
    mesh.enableEdgesRendering();
    mesh.edgesWidth = 10.0;
    mesh.edgesColor = Color4.FromColor3(color, 1.0);
    // line width is computed as mesh.edgesWidth / edgeRenderer.edgesWidthScalerForOrthographic
    mesh.edgesRenderer.edgesWidthScalerForOrthographic = 4000; // default is 1000
    mesh['displayName'] = location.name;
    mesh.setParent(this);
    this.guiFactoryService.addGuiLabel(location.name, mesh, this.id);
  }

  private createPolygon(location: Location): void {
    const points = this.getWorldCoordinates(location);
    const poly_tri = new PolygonMeshBuilder(location.id, points, this.getScene(), earcut);
    const polygon = poly_tri.build(false);
    polygon.rotate(Axis.X, -Math.PI / 2, Space.WORLD);
    polygon.position.z = Z_OFFSET;
    this.setPropertiesFromLocation(location, polygon);
  }

  private createCircle(location: Location): void {
    const center = this.getWorldCoordinates(location)[0];
    const radius = location.shape.radiusMeters;
    const tessellation = 64;
    const circle = MeshBuilder.CreateDisc(location.id, { radius, tessellation }, this.getScene());
    circle.position = new Vector3(center.x, center.y, Z_OFFSET);
    this.setPropertiesFromLocation(location, circle);
  }
}
