import { Injectable, NgZone } from '@angular/core';
import type { Widget } from '../../models';
import { WidgetCustom, WidgetHTML, WidgetImage, WidgetLineGraph, WidgetVideo } from '../../models';
import { Utils } from '../../utils';
import type { IRocosTelemetryMessage } from '@team-rocos/rocos-js';
import type { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { RobotDefinitionService } from '../robot/robot-definition.service';
import { RocosClientService } from '../rocos-client';
import { BabylonRendererService } from './babylon-renderer.service';
import { LaserscanService } from './laserscan.service';
import { OccupancymapService } from './occupancymap.service';
import { PointcloudService } from './pointcloud.service';
import { GeoJsonService } from './geo-json.service';
import { Quaternion, Vector3 } from '@babylonjs/core';
import { AppService } from '@shared/services';
import { lightPropertyValueTypes } from './primitives/lightPrimitives';
import { boolHasValue, boolHasValueAndTrue, numberHasValue } from './primitives/validation';
import { RendererModelService } from './renderer-model.service';
import { RendererSelectionService } from './renderer-selection.service';
import { MeshFactoryService } from './factories/mesh-factory.service';
import type { IMesh } from './primitives/visualizer/interface/IMesh';
import type { IScene } from './primitives/visualizer/interface/IScene';
import type { ScenePropertyType } from './primitives/scenePrimitives';
import { scenePropertyValueTypes } from './primitives/scenePrimitives';
import type { ISceneOptionsHiddenFeatures } from './primitives/visualizer/interface/ISceneOptionsHiddenFeatures';
import { MaterialFactoryService } from './factories/material-factory.service';
import { GuiFactoryService } from './factories/gui-factory.service';
import type { RendererDefaultObjectTypes } from './primitives/objectPrimitives';
import { defaultMeshOps, getObjectPrimitiveMesh } from './primitives/objectPrimitives';
import type { SceneManager } from './primitives/visualizer';
import { VizBinding, VizBoundSource } from './primitives/visualizer';
import { crsToWorld, worldToCrs } from './transform';
import { BabylonInspectorService } from './inspector/babylon-inspector.service';
import type { RobotGraph } from './nodes/robot-graph';
import type { Voxels } from './nodes/voxels';
import { VoxelsDefaultDeadline, VoxelsDefaultTTL } from './nodes/voxels';

/**
 * BABYLON JS normal mode is the following
 *        Y (Up / Altitude)   in ROS Z is up
 *        ^
 *        |
 *        |
 *        |
 *        |-------------> X (East)
 *       /
 *      /
 *     /
 *    v
 *   Z (South)
 *
 *  Babylon is put into "Right Hand" mode in order for positive rotation to be anticlockwise, same as ROS
 *
 *
 *  Expected ROS Input format is https://www.ros.org/reps/rep-0103.html (East, North, Up)
 *
 *        Z (World Altitude | Object's Up, in ROS Z is up)
 *        ^
 *        |    ^ Y (World North | Object's Left)
 *        |   /
 *        |  /
 *        | /
 *         --------------> X (World East, Object's Forwards)
 *
 *  This system expects ROS as input where UP position is Z
 *  (Babylon environment has been rotated 90 degrees around X axis in order to compensate for original orientation)
 *
 */

@Injectable()
export class RendererService {
  public static readonly laserScanAreaSuffix = '-area';
  public static readonly laserScanRaysSuffix = '-rays';
  public static readonly pointCloudSuffix = '-pc';
  public static readonly defaultPointCloudFramesMaxCapability = 1;

  public lowLatencyRendering: boolean = false;
  public currentFrameNumber: number = 0;
  public currentFrameTime: number = 0;
  public currentFrameDataValue: unknown;
  public playing: 'paused' | 'playing' | 'playingLive' = 'paused';

  public debugFrameGenerateTime: number = 0; // milliseconds it took to generate the frame
  public debugAverageFPS: string = '';
  public debugNumSceneMeshes: number = 0;
  public debugIsDebugMode: boolean = false;

  private _isReady$ = new BehaviorSubject<boolean>(false);
  public get isReady$() {
    return this._isReady$.asObservable();
  }

  private canvasNativeElement: HTMLCanvasElement;
  private renderObjectUpdateInterval;
  private startFrameGenWatch: number = 0; // time when the new frame started being generated
  private areSceneUpdates: boolean = false; // if there is a fresh frame for the babylonRenderer to render
  private tempDataBuffer = []; // stores the data from all sources available to the current frame, item in array is
  private subscription: Subscription[] = [];

  private get sceneManager(): SceneManager {
    return this.rendererModelService.sceneManager;
  }

  public constructor(
    public babylonRenderer: BabylonRendererService,
    private rocosClientService: RocosClientService,
    private robotDefinitionService: RobotDefinitionService,
    private occupancymapService: OccupancymapService,
    private ngZone: NgZone,
    private appService: AppService,
    private rendererModelService: RendererModelService,
    private rendererSelectionService: RendererSelectionService,
    private meshFactoryService: MeshFactoryService,
    private materialFactoryService: MaterialFactoryService,
    private guiFactoryService: GuiFactoryService,
    private geoJSONService: GeoJsonService,
    private babylonInspectorService: BabylonInspectorService,
  ) {
    /**
     * Important: The following is used by Local Ops Scrips via rocos.web3D.xx (Most notably: Diligent)
     * And yes, this is BAD, and we need to find a way to migrate the diligent scripts so they don't
     * depend on the internal workings of portal. If you are still reading, I'm very sorry.
     *
     * @deprecated Please use this.meshFactoryService.setMeshValue and this.rendererSelectionService instead
     */
    this['setMeshValue'] = this.meshFactoryService.setMeshValue;
    this['resetGizmoManager'] = this.rendererSelectionService.resetGizmoManager;
    this['boolHasValue'] = boolHasValue;
    this['boolHasValueAndTrue'] = boolHasValueAndTrue;
    this['numberHasValue'] = numberHasValue;
  }

  public loadScene(sceneJsonObject: IScene): void {
    this.sceneManager.loadSceneFromJson(JSON.parse(JSON.stringify(sceneJsonObject)));
  }

  public generateScene(canvasNativeElement: HTMLCanvasElement): void {
    this.canvasNativeElement = canvasNativeElement;
    this.babylonRenderer.setup(this.canvasNativeElement);

    if (this.sceneManager.sceneOptions.clearColor)
      this.syncSceneValue('clearColor', this.sceneManager.sceneOptions.clearColor);

    if (this.sceneManager.sceneOptions.ambientColor)
      this.syncSceneValue('ambientColor', this.sceneManager.sceneOptions.ambientColor);

    if (this.sceneManager.sceneOptions.skybox) this.syncSceneValue('skybox', this.sceneManager.sceneOptions.skybox);

    if (
      this.sceneManager.sceneOptions.shadowsEnabled !== undefined &&
      this.sceneManager.sceneOptions.shadowsEnabled != null
    ) {
      this.syncSceneValue('shadowsEnabled', this.sceneManager.sceneOptions.shadowsEnabled);
    }

    Object.keys(this.sceneManager.lights).forEach((key) => {
      const lightData = this.sceneManager.lights[key];
      this.addLightToRenderer(key, lightData);
    });

    // --- Materials ---
    Object.keys(this.sceneManager.materials).forEach((key) => {
      const matData = this.sceneManager.materials[key];
      this.addMaterialToRenderer(key, matData);
    });

    // --- Meshes --
    Object.keys(this.sceneManager.getMeshes()).forEach((key) => {
      const meshData = this.sceneManager.findMesh(key);
      this.meshFactoryService.addMeshToRenderer({
        meshId: key,
        meshData,
      });
    });
    // now that the meshes are drawn assign correct parents
    this.meshFactoryService.setParentForObject();
    // --- Cameras ---
    // cameras loaded after Meshes as some cameras can bind to follow a specific mesh
    Object.keys(this.sceneManager.cameras).forEach((key) => {
      const camData = this.sceneManager.cameras[key];
      this.babylonRenderer.addCameraToScene(key, camData);

      if (this.sceneManager.sceneOptions['defaultCamera'] && key === this.sceneManager.sceneOptions['defaultCamera']) {
        this.babylonRenderer.setActiveCamera(key, camData);
      }
    });

    // --- Render Loop ---
    this.ngZone.runOutsideAngular(() => {
      this.babylonRenderer.engine.runRenderLoop(() => {
        // this lives updates all the time so camera is smooth
        this.babylonRenderer.scene.render();

        // confirm the frame updates is rendered
        if (this.areSceneUpdates) {
          this.areSceneUpdates = false;
        }
      });
    });

    // --- Gizmo Manager ---
    this.rendererSelectionService.resetGizmoManager();
    this.resize();
    this.startAnimation();

    this._isReady$.next(true);
  }

  public setActiveCamera(key: string): void {
    const cameraData = this.sceneManager.cameras[key];
    this.babylonRenderer.setActiveCamera(key, cameraData);
  }

  public focusCameraOnMesh(meshId: string): void {
    this.babylonRenderer.focusCameraOnMesh(meshId);
  }

  // the data that should be saved
  public getSceneDefinition(): IScene {
    return {
      sceneOptions: this.sceneManager.sceneOptions,
      projectLocation: this.sceneManager.projectLocation,
      cameras: this.sceneManager.cameras,
      lights: this.sceneManager.lights,
      boundSources: this.sceneManager.boundSources,
      materials: this.sceneManager.materials,
      skyboxes: this.sceneManager.skyboxes,
      meshes: this.sceneManager.getMeshes(),
      panels: this.sceneManager.panels,
      robots: this.sceneManager.robots,
    };
  }

  public getVideoWidgets(): Widget[] {
    if (this.sceneManager.panels?.['videoPanel']?.widgets) {
      const widgets = this.sceneManager.panels['videoPanel'].widgets;

      return widgets.map((widget) => {
        switch (widget.widgetType) {
          case 'video':
            return WidgetVideo.fromModel(widget as WidgetVideo);
          case 'image':
            return WidgetImage.fromModel(widget);
          case 'html':
            return WidgetHTML.fromModel(widget);
          case 'custom':
            return WidgetCustom.fromModel(widget);
          case 'line-graph':
          case 'line-graph-v2':
            return WidgetLineGraph.fromModel(widget);
          case 'web-component':
            return widget;
          default:
            return undefined;
        }
      });
    }
    return [];
  }

  public updateVideoWidgets(widgets: Widget[]): void {
    if (this.sceneManager.panels?.['videoPanel']?.widgets) {
      this.sceneManager.panels['videoPanel'].widgets = widgets;
    }
  }

  public getJSWidgets(): Widget[] {
    if (this.sceneManager.panels?.['jsPanel']?.widgets) {
      return this.sceneManager.panels['jsPanel'].widgets;
    }
    return [];
  }

  public updateJSWidgets(widgets: Widget[]): void {
    if (this.sceneManager.panels && !this.sceneManager.panels['jsPanel']?.widgets) {
      this.sceneManager.panels['jsPanel'] = {
        widgets: [],
        title: 'Javascript Panel',
      };
    }

    if (this.sceneManager.panels?.['jsPanel']?.widgets) {
      this.sceneManager.panels['jsPanel'].widgets = widgets;
    }
  }

  public updateCallsigns(callsigns: string[]): void {
    if (!callsigns) return;

    const obj = this.sceneManager.robots;
    const currentCallsigns = Object.keys(obj);
    currentCallsigns.forEach((callsign) => {
      if (!callsigns.includes(callsign)) this.deleteRobot(callsign);
    });

    const newCallsigns = callsigns.filter((c) => {
      return !currentCallsigns.includes(c);
    });

    newCallsigns.forEach((callsign) => {
      if (!obj[callsign]) obj[callsign] = {};

      this.robotDefinitionService.getSettingsForRobot(this.appService.projectId, callsign).subscribe((robSettings) => {
        robSettings.items.forEach((s) => {
          if (s.value.id === 'ops-local-settings') {
            this.addRobot(callsign, s.value.settings);
          }
        });
      });
    });

    Object.keys(obj).forEach((key) => {
      if (!callsigns.includes(key)) delete obj[key];
    });

    this.sceneManager.robots = obj;
  }

  public getCallsigns(): string[] {
    const callsigns = [];
    Object.keys(this.sceneManager.robots).forEach((key) => {
      callsigns.push(key);
    });

    return callsigns;
  }

  public getHiddenFeatures(): ISceneOptionsHiddenFeatures {
    if (this.sceneManager.sceneOptions?.hiddenFeatures) {
      return this.sceneManager.sceneOptions.hiddenFeatures;
    }

    return {};
  }

  public resize(): void {
    if (!this.babylonRenderer.engine) return;
    this.babylonRenderer.engine.resize();
    this.sceneManager.camerasByKey.forEach((camera) => {
      if (camera.type === 'orthographic') {
        this.babylonRenderer.setOrthographicCameraPerspective(camera.key, camera.zoom);
      }
    });
  }

  public deleteRobot(callsign: string): void {
    this.deleteObject('robot-' + callsign, 'mesh');
  }

  /** Adds a default object to both the Scene Manager (storage) and the Babylon Renderer
   *  Returns the name automatically given to the object
   */
  public addDefaultObject(type: RendererDefaultObjectTypes, id?: string): string {
    const objectId = id || crypto.randomUUID();
    const meshData = getObjectPrimitiveMesh(type);
    this.addMesh(objectId, meshData);
    return objectId;
  }

  /** Removes the object from both the Scene Manager (storage) as well as Babylon renderer */
  public deleteObject(objectKey, objectType, isChildDelete?) {
    if (this.rendererSelectionService.activeObject$.getValue()?.key === objectKey)
      this.rendererSelectionService.clearHighlightedMesh();

    this.deleteChildMeshesForObject(objectKey);
    this.deleteBindingsForObject(objectKey);
    this.guiFactoryService.removeAllGuiControlsOfParentName(objectKey);

    switch (objectType) {
      case 'light': {
        this.sceneManager.deleteLight(objectKey);
        this.babylonRenderer.deleteLight(objectKey);
        break;
      }
      case 'mesh': {
        this.sceneManager.deleteMesh(objectKey);
        this.babylonRenderer.deleteMesh(objectKey);
        break;
      }
      case 'camera': {
        this.sceneManager.deleteCamera(objectKey);
        this.babylonRenderer.deleteCamera(objectKey);
        break;
      }
    }

    // reinitialize subscriptions
    if (!boolHasValueAndTrue(isChildDelete)) {
      this.cleanSubscribeToBoundSubscriptions();
    }
  }

  public generateBoundUpdate(
    bindingKey: string,
    boundSourceKey: string,
    valueExpression: string,
    msg: IRocosTelemetryMessage,
  ) {
    // find the $msg reference, swap it out and run enum
    const binding = new VizBinding(bindingKey, boundSourceKey, valueExpression, null);
    const mesh = this.babylonRenderer.getMesh(binding.meshId);

    // get the value that is to be assigned to the bound binding key (e.g. what will be assigned to robo1.position.x)
    const evalOutcome = binding.getBoundValue(msg.payload);
    if (!evalOutcome.evalSuccess) return null;

    const evalledPayloadValue = evalOutcome.evaluatedResponseObject;
    if (!evalledPayloadValue) return null;

    // deal with cases, default is a property that matches directly
    switch (binding.propertyId) {
      case 'rotationQuaternion': {
        const quaternion = new Quaternion(
          evalledPayloadValue[0],
          evalledPayloadValue[1],
          evalledPayloadValue[2],
          evalledPayloadValue[3],
        );

        let finalVector = quaternion.toEulerAngles();
        const meshProps = this.sceneManager.findMesh(binding.meshId);
        if (meshProps?.rotation) finalVector = finalVector.add(this.babylonRenderer.getVector(meshProps.rotation));
        mesh.rotationQuaternion = null;
        mesh.rotation = finalVector; // don't use quaternions as it blocks use of rotation after that
        break;
      }
      case 'occupancyMap': {
        const occMapProps = this.sceneManager.findMesh(binding.meshId);
        if (occMapProps?.ops && !occMapProps.ops.visible) break;

        const imageData = this.occupancymapService.getImageDataFromTopic(evalledPayloadValue);
        this.babylonRenderer.renderOccupancyMap(
          imageData,
          evalledPayloadValue.info.width,
          evalledPayloadValue.info.height,
          evalledPayloadValue.info.resolution,
          evalledPayloadValue.info.origin.position,
          binding.meshId,
        );

        break;
      }
      case 'laserScan': {
        const laserProps = this.sceneManager.findMesh(binding.meshId);
        mesh.visibility = 0;

        if (laserProps?.ops && !laserProps.ops.visible) break;

        if (
          boolHasValueAndTrue(laserProps?.renderRays) ||
          boolHasValueAndTrue(laserProps?.renderArea) ||
          boolHasValueAndTrue(laserProps?.renderPointCloud)
        ) {
          const startPoint = new Vector3(0, 0, 0);
          const lsService = new LaserscanService(startPoint);
          lsService.fromROSLaserScan(evalledPayloadValue);

          let scanPosition: Vector3 = new Vector3(0, 0, 0);
          let scanRotation: Vector3 = new Vector3(0, 0, 0);

          if (laserProps?.parent) {
            const parentMesh = this.babylonRenderer.getMesh(laserProps.parent);
            scanPosition = parentMesh.position.clone();
            scanRotation = parentMesh.rotation.clone();
          }

          // TODO: apply the default offsets
          if (boolHasValueAndTrue(laserProps?.renderRays)) {
            this.babylonRenderer.renderLaserScanRays(
              laserProps.key,
              RendererService.laserScanRaysSuffix,
              lsService,
              laserProps,
              scanPosition,
              scanRotation,
              this.babylonRenderer.scene,
            );
          }

          if (boolHasValueAndTrue(laserProps?.renderArea)) {
            this.babylonRenderer.renderLaserScanRayArea(
              laserProps.key,
              RendererService.laserScanAreaSuffix,
              lsService,
              laserProps,
              scanPosition,
              scanRotation,
              this.babylonRenderer.scene,
            );
          }

          if (boolHasValueAndTrue(laserProps?.renderPointCloud)) {
            const pcService = new PointcloudService();
            pcService.loadFromLaserScanRays(lsService.rays);

            let maxNumPointCloudFrames = RendererService.defaultPointCloudFramesMaxCapability;
            if (laserProps?.maxNumOfInstances) maxNumPointCloudFrames = laserProps.maxNumOfInstances;

            this.babylonRenderer.renderPointCloud(
              laserProps.key,
              RendererService.pointCloudSuffix,
              pcService,
              scanPosition,
              scanRotation,
              laserProps.material,
              maxNumPointCloudFrames,
            );
          }
        }

        break;
      }
      case 'pointcloud': {
        const pcProps = this.sceneManager.findMesh(binding.meshId);
        mesh.visibility = 0;

        if (pcProps?.ops && !pcProps.ops.visible) break;
        let isBase64Encoded = true;
        if (boolHasValue(pcProps?.base64Encoded)) isBase64Encoded = pcProps.base64Encoded;

        const pcService = new PointcloudService();
        pcService.loadFromPointCloud2(
          evalledPayloadValue.data,
          evalledPayloadValue.width,
          evalledPayloadValue.point_step,
          isBase64Encoded,
        );

        let pointCloudMaterialId = null;
        if (pcProps?.material) pointCloudMaterialId = pcProps.material;

        let scanPosition: Vector3 = new Vector3(0, 0, 0);
        let scanRotation: Vector3 = new Vector3(0, 0, 0);

        if (pcProps?.parent) {
          const parentMesh = this.babylonRenderer.getMesh(pcProps.parent);
          scanPosition = parentMesh.position.clone();
          scanRotation = parentMesh.rotation.clone();
        }

        let maxCapOfPointClouds = RendererService.defaultPointCloudFramesMaxCapability;
        if (pcProps?.maxNumOfInstances) maxCapOfPointClouds = pcProps.maxNumOfInstances;

        this.babylonRenderer.renderPointCloud(
          bindingKey + '-internal',
          RendererService.pointCloudSuffix,
          pcService,
          scanPosition,
          scanRotation,
          pointCloudMaterialId,
          maxCapOfPointClouds,
        );

        break;
      }
      case 'voxels': {
        const octreeProperties = this.sceneManager.findMesh(binding.meshId);
        if (octreeProperties?.ops && !octreeProperties?.ops?.visible) break;

        const voxels = mesh as Voxels;
        voxels.renderBoundUpdate(evalledPayloadValue);
        break;
      }

      case 'robotMapLive': {
        if (!mesh) break;
        const robotMap = mesh as RobotGraph;
        robotMap.renderBoundUpdate(evalledPayloadValue);
        break;
      }

      case 'geoJSON': {
        const meshData = this.sceneManager.findMesh(binding.meshId);
        if (!meshData?.ops?.visible || meshData.geoJSON?.mapId) break;
        this.geoJSONService.renderGeoJson(binding.meshId, meshData, evalledPayloadValue);
        break;
      }
      case 'addPoint': {
        // for line type only, add the point to the array and rebuild the mesh
        this.tempDataBuffer.push(evalledPayloadValue);
        const addPointMesh = this.sceneManager.findMesh(binding.meshId);
        let trailLength = 500;
        if (numberHasValue(addPointMesh?.trailLength)) trailLength = addPointMesh.trailLength;
        if (this.tempDataBuffer.length > trailLength)
          this.tempDataBuffer.splice(0, this.tempDataBuffer.length - trailLength);
        this.rebuildLine(mesh, this.tempDataBuffer);
        break;
      }
      default: {
        const props = binding.propertyId?.split('.');
        if (props?.length === 2 && ['position', 'rotation', 'scaling'].indexOf(props[0]) >= 0) {
          const targetValue = this.getPayloadValueFromMesh(binding.meshId, binding.propertyId, evalledPayloadValue);
          const defaultMesh = this.sceneManager.findMesh(binding.meshId);

          // If we are using WGS84, map to local coordinates
          if (defaultMesh.boundSources?.coordinateSpace === 'WGS84' && props[0] === 'position') {
            const viewLocation = this.sceneManager.projectLocation;
            const meshPositionWGS84: Vector3 = worldToCrs(viewLocation, 'WGS84', mesh.position);
            meshPositionWGS84[props[1]] = targetValue;
            const meshPositionLocal: Vector3 = crsToWorld(viewLocation, 'WGS84', meshPositionWGS84);
            const transformedValue = meshPositionLocal[props[1]];

            return {
              property: binding.propertyId,
              meshId: binding.meshId,
              value: transformedValue,
            };
          }

          return {
            property: binding.propertyId,
            meshId: binding.meshId,
            value: targetValue,
          };
        }

        // assign the final value to the mesh, not sure if array method or eval method is faster currently
        this.babylonRenderer.applyValueToMesh(
          binding.propertyId,
          mesh,
          this.getPayloadValueFromMesh(binding.meshId, binding.propertyId, evalledPayloadValue),
        );
        break;
      }
    }

    return null;
  }

  public setVoxelColorMethod(key: string, value: string) {
    const voxels = this.babylonRenderer.getMesh(key) as Voxels;
    if (voxels) voxels.setColourMethod(value);
  }

  public getVoxelColorMethod(key: string): string {
    const voxels = this.babylonRenderer.getMesh(key) as Voxels;
    if (voxels) return voxels.getColorMethod();
    return '';
  }

  public showHideLaserArea(key, isEnabled) {
    const laserArea = this.babylonRenderer.getNode(key + RendererService.laserScanAreaSuffix);
    if (laserArea) laserArea.setEnabled(isEnabled);
  }

  public showHideLaserRays(key, isEnabled) {
    const laserRays = this.babylonRenderer.getNode(key + RendererService.laserScanRaysSuffix);
    if (laserRays) laserRays.setEnabled(isEnabled);
  }

  public showHideLaserPoints(key, isEnabled) {
    const laserPoints = this.babylonRenderer.getNode(key + RendererService.pointCloudSuffix);
    if (laserPoints) laserPoints.setEnabled(isEnabled);
  }

  public rebuildLine(oldLine, points: any[]) {
    const meshId = oldLine.id;
    const meshColor = oldLine.color;

    const vectorPoints = [];
    points.forEach((p) => {
      vectorPoints.push(new Vector3(p[0], p[1], p[2]));
    });

    this.babylonRenderer.scene.removeMesh(oldLine);
    oldLine.dispose();
    oldLine = null;

    const newLine = this.babylonRenderer.createLines(meshId + 'temp', {
      points: vectorPoints,
    });

    // copy across basic info
    newLine.color = meshColor;
    newLine.id = meshId;
  }

  /**
   * Sets a property of the Scene object in both the global data
   * structure scene manager as well as in the live babylonRenderer
   *
   * @param property the id of the property
   * @param newValue new value to set the property to
   * @param valueType the type {'color3' | 'color4' | 'boolean' | 'number' | 'skybox'}
   */
  public syncSceneValue(property: ScenePropertyType, newValue: string | boolean) {
    const valueType = scenePropertyValueTypes[property];
    switch (valueType) {
      case 'color3': {
        this.sceneManager.sceneOptions[property as string] = newValue;
        const col3Value = this.babylonRenderer.getColor3(newValue);
        this.babylonRenderer.scene[property] = col3Value;
        break;
      }
      case 'color4': {
        this.sceneManager.sceneOptions[property as string] = newValue;
        const col4Value = this.babylonRenderer.getColor4(newValue);
        this.babylonRenderer.scene[property] = col4Value;
        break;
      }
      case 'boolean': {
        this.sceneManager.sceneOptions[property as string] = newValue;
        const boolValue = JSON.parse(newValue as string);
        this.babylonRenderer.scene[property] = boolValue;
        break;
      }
      case 'number': {
        this.sceneManager.sceneOptions[property as string] = newValue;
        const numValue = parseFloat(newValue as string);
        this.babylonRenderer.scene[property] = numValue;

        break;
      }
      case 'skybox': {
        if (newValue === undefined || newValue === null || newValue === '') {
          this.sceneManager.sceneOptions[property as string] = null;
          // if the skybox already exists then remove it
          const skyboxMesh = this.babylonRenderer.scene.getMeshByID('skybox-box');
          if (skyboxMesh != null) {
            skyboxMesh.dispose();
          }
        } else {
          this.sceneManager.sceneOptions[property as string] = newValue;
          const skyboxId = this.sceneManager.sceneOptions.skybox;
          const skyboxData = this.sceneManager.skyboxes[skyboxId];

          if (skyboxData) {
            this.babylonRenderer.createSkybox(skyboxData);
          }
        }
        break;
      }
    }
  }

  /**
   * Updates the stored scene data structure with camera properties
   * as well as updates the babylonRenderer camera properties
   */
  public syncCameraValue(cameraId: string, property: string, newValue: any, valueType: string) {
    const babylonRendererCamera = this.babylonRenderer.getCamera(cameraId);

    switch (valueType) {
      case 'string': {
        this.sceneManager.cameras[cameraId][property] = newValue;
        babylonRendererCamera[property] = newValue;
        break;
      }
      case 'number': {
        this.sceneManager.cameras[cameraId][property] = newValue;
        const numValue = parseFloat(newValue);
        babylonRendererCamera[property] = numValue;
        break;
      }
      case 'vector3': {
        let arrayValue = null;

        if (newValue !== '') {
          arrayValue = newValue.toString().split(',');
          this.sceneManager.cameras[cameraId][property] = arrayValue;
        } else {
          this.sceneManager.cameras[cameraId][property] = [0, 0, 0];
        }

        if (arrayValue != null) {
          const vect3Value = this.babylonRenderer.getVector(arrayValue);
          babylonRendererCamera[property] = vect3Value;
        } else {
          babylonRendererCamera[property] = null;
        }
        break;
      }
      case 'color3': {
        this.sceneManager.cameras[cameraId][property] = newValue;
        const col3Value = this.babylonRenderer.getColor3(newValue);
        babylonRendererCamera[property] = col3Value;
        break;
      }
      case 'color4': {
        this.sceneManager.cameras[cameraId][property] = newValue;
        const col4Value = this.babylonRenderer.getColor4(newValue);
        babylonRendererCamera[property] = col4Value;
        break;
      }
      case 'boolean': {
        this.sceneManager.cameras[cameraId][property] = newValue;
        const boolValue = JSON.parse(newValue);
        switch (property) {
          case 'enabled': {
            babylonRendererCamera.setEnabled(boolValue);
            break;
          }
          default: {
            babylonRendererCamera[property] = boolValue;
            break;
          }
        }
        break;
      }
    }
  }

  /**
   *  Updates the stored scene data structure with light properties
   *  as well as updates the babylonRenderer lights properties
   *  This is only used where the lights properties in the scene
   *  storage and the babylonRenderer are the same
   *  (e.g. diffuse is used in both)
   */
  public syncLightValue(lightId: string, property: string, newValue: any) {
    const babylonRendererLight = this.babylonRenderer.getLight(lightId);
    const valueType = lightPropertyValueTypes[property];

    switch (valueType) {
      case 'string': {
        this.sceneManager.lights[lightId][property] = newValue;
        babylonRendererLight[property] = newValue;
        break;
      }
      case 'number': {
        this.sceneManager.lights[lightId][property] = newValue;

        const numValue = parseFloat(newValue);
        babylonRendererLight[property] = numValue;
        break;
      }
      case 'vector3': {
        let arrayValue = null;

        if (newValue !== '') {
          arrayValue = newValue.toString().split(',');
          this.sceneManager.lights[lightId][property] = arrayValue;
        } else {
          this.sceneManager.lights[lightId][property] = [0, 0, 0];
        }

        if (arrayValue != null) {
          const vect3Value = this.babylonRenderer.getVector(arrayValue);
          babylonRendererLight[property] = vect3Value;
        } else {
          // babylonRendererLight[property] = this.vizScene.getVector3([0, 0, 0]);
          babylonRendererLight[property] = null;
        }
        break;
      }
      case 'color3': {
        this.sceneManager.lights[lightId][property] = newValue;
        const col3Value = this.babylonRenderer.getColor3(newValue);
        babylonRendererLight[property] = col3Value;
        break;
      }
      case 'color4': {
        this.sceneManager.lights[lightId][property] = newValue;
        const col4Value = this.babylonRenderer.getColor4(newValue);
        babylonRendererLight[property] = col4Value;
        break;
      }
      case 'boolean': {
        this.sceneManager.lights[lightId][property] = newValue;
        const boolValue = JSON.parse(newValue);

        // handle special cases
        switch (property) {
          case 'enabled': {
            babylonRendererLight.setEnabled(boolValue);
            break;
          }
          default: {
            babylonRendererLight[property] = boolValue;
            break;
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  public deleteChildMeshesForObject(objectId: string) {
    Object.keys(this.sceneManager.getMeshes()).forEach((mesh) => {
      const foundMesh = this.sceneManager.findMesh(mesh);
      if (foundMesh?.parent === objectId) {
        this.deleteObject(mesh, 'mesh', true);
      }
    });
  }

  public deleteBinding(bindingKey) {
    if (this.sceneManager.bindingsKeyExists[bindingKey]) {
      // clean up any internal rendering objects used during binding
      if (bindingKey.endsWith('.laserScan') || bindingKey.endsWith('.pointcloud')) {
        const id = bindingKey + '-internal';

        // clean up laserScan internal artifacts (lines)
        if (this.babylonRenderer.laserScans[id]) {
          const internalMesh = this.babylonRenderer.getMesh(id);
          if (internalMesh != null) internalMesh.dispose(false); // also destroy children
          delete this.babylonRenderer.laserScans[id];
        }

        // clean up dots on end of laserscan
        if (this.babylonRenderer.particleSystems[id]) this.babylonRenderer.resetPointCloud(id);
      }

      const sourceKey = this.sceneManager.bindingsSourceKey[bindingKey];
      delete this.sceneManager.boundSources[sourceKey].bindings[bindingKey];

      // there are no bindings left in this source, so remove it
      if (Object.keys(this.sceneManager.boundSources[sourceKey].bindings).length === 0) {
        delete this.sceneManager.boundSources[sourceKey];
      }

      // refresh the bindings lookups
      this.sceneManager.updateBindingsKeyArray();
    }
  }

  /** Removes any bindings related to an object like a mesh, light etc */
  public deleteBindingsForObject(objectId) {
    // find a binding that matches the object
    Object.keys(this.sceneManager.boundSources).forEach((bs) => {
      Object.keys(this.sceneManager.boundSources[bs].bindings).forEach((bindingKey) => {
        if (bindingKey.split('.')[0] && bindingKey.split('.')[0] === objectId) {
          this.deleteBinding(bindingKey);
        }
      });
    });
  }

  /**
   * Assigns a value to the mesh
   */
  public updateBinding(updatedBinding: VizBinding) {
    const meshPropertyKey = updatedBinding.key; // key example is robot1.position.x, this doesn't change
    this.deleteBinding(meshPropertyKey);

    if (
      updatedBinding.valueExpression.trim() === '' ||
      (updatedBinding.valueExpression.trim().indexOf('$object') === -1 &&
        updatedBinding.valueExpression.trim().indexOf('$msg') === -1) ||
      updatedBinding.boundSourceKey.trim() === ''
    ) {
      this.cleanSubscribeToBoundSubscriptions();
      return;
    }
    // add it into a boundSource now (e.g. robot1/rosbridge/pose/)
    if (!this.sceneManager.boundSources[updatedBinding.boundSourceKey]) {
      this.sceneManager.boundSources[updatedBinding.boundSourceKey] = {
        type: 'stream',
        bindings: {},
      };
    }

    this.sceneManager.boundSources[updatedBinding.boundSourceKey].bindings[meshPropertyKey] = {
      type: 'json',
      valueExpression: updatedBinding.valueExpression,
      disabled: updatedBinding?.disabled ?? false,
    };
    // refresh the bindings lookups
    this.sceneManager.updateBindingsKeyArray();
    // force the update to the subscribed streams
    this.cleanSubscribeToBoundSubscriptions();
  }
  public unsubscribeBoundSubscriptions() {
    this.subscription.forEach((sub) => {
      sub.unsubscribe();
    });
  }
  public cleanSubscribeToBoundSubscriptions() {
    this.unsubscribeBoundSubscriptions();
    // prepare robot bindings, by grouping it by callsign for later rendering
    const robotBindings: Record<string, Record<string, VizBoundSource>> = {};
    Object.keys(this.sceneManager.boundSources).forEach((dsKey) => {
      const bSource = new VizBoundSource(dsKey);
      let dataUri: string;

      const bindingKeys = Object.keys(this.sceneManager.boundSources[dsKey].bindings);
      const isVoxelsSource = bindingKeys.some((key) => key.endsWith('voxels'));
      if (isVoxelsSource) {
        dataUri = Utils.addUriParam(bSource.dataUri, 'txp', 'FF');
        dataUri = Utils.addUriParam(dataUri, 'dqp', 'FIFO');
        dataUri = Utils.addUriParam(dataUri, 'dl', VoxelsDefaultDeadline);
        dataUri = Utils.addUriParam(dataUri, 'ttl', VoxelsDefaultTTL);
      } else {
        dataUri = Utils.addUriParam(bSource.dataUri, 'int', '200ms');
      }

      bSource.dataUri = dataUri;
      bSource.bindings = this.sceneManager.boundSources[dsKey].bindings;
      if (!robotBindings[bSource.robotCallSign]) {
        robotBindings[bSource.robotCallSign] = {
          [bSource.dataUri]: bSource,
        };
      } else if (!robotBindings[bSource.robotCallSign][bSource.dataUri]) {
        robotBindings[bSource.robotCallSign][bSource.dataUri] = bSource;
      } else {
        robotBindings[bSource.robotCallSign][bSource.dataUri].bindings = {
          ...robotBindings[bSource.robotCallSign][bSource.dataUri].bindings,
          ...bSource.bindings,
        };
      }
    });

    Object.keys(robotBindings).forEach((robotCallsign) => {
      // build a list of sources unique to this robot to subscribe to
      const robotsSourcesStrings: string[] = Object.keys(robotBindings[robotCallsign]).filter((sourceKey) => {
        // filter out disabled bindings
        return !Object.values(robotBindings[robotCallsign][sourceKey].bindings).every((binding) => binding.disabled);
      });

      // subscribe to the robot with unique sources
      const sub = this.rocosClientService
        .subscribeV2(this.appService.projectId, [robotCallsign], robotsSourcesStrings)

        .subscribe((msg: IRocosTelemetryMessage) => {
          // process inbound messages from the subscribed sources
          // only process this if low latency rendering is on OR a binding is ready to be captured and rendered
          if (this.shouldProcessBoundMessage()) {
            this.processSubscriptionMessage(msg, robotBindings?.[msg.callsign]?.[msg.source]);
          }
        });
      this.subscription.push(sub);
    });
  }

  public setDebugMode() {
    this.babylonInspectorService.showInspector(this.babylonRenderer.scene);
  }

  public terminateRendering() {
    if (this.babylonRenderer.engine) this.babylonRenderer.engine.stopRenderLoop();
  }

  public cleanUp() {
    this.pause();
    this.babylonRenderer.cleanUp();
    this.guiFactoryService.cleanUp();
    this.babylonRenderer = null;
    this.rendererSelectionService.cleanUp();
    clearInterval(this.renderObjectUpdateInterval);
    this.rendererModelService.cleanUp();
  }

  public startAnimation() {
    // Animation Tick Updates to the data
    this.renderObjectUpdateInterval = setInterval(() => {
      if (this.playing !== 'paused') {
        this.currentFrameNumber++;
        this.currentFrameTime = this.currentFrameNumber * this.sceneManager.frameInterval;
        this.generateSceneUpdates();
      }
    }, this.sceneManager.frameInterval);

    this.playing = 'playingLive';
  }

  public play() {
    this.playing = 'playing';
  }

  public playLive() {
    this.playing = 'playingLive';
  }

  public pause() {
    this.playing = 'paused';
  }

  public backFrames(numFrames: number) {
    this.pause();
    if (this.currentFrameNumber > numFrames) {
      this.currentFrameNumber -= numFrames;
      this.currentFrameTime -= numFrames * this.sceneManager.frameInterval;
    } else {
      this.currentFrameNumber = 0;
      this.currentFrameTime = 0;
    }

    this.currentFrameDataValue = null;
    this.generateSceneUpdates();
  }

  public forwardFrames(numFrames: number) {
    this.pause();
    if (this.currentFrameTime + numFrames * this.sceneManager.frameInterval <= this.sceneManager.endTime) {
      this.currentFrameNumber += numFrames;
      this.currentFrameTime += numFrames * this.sceneManager.frameInterval;
    } else {
      this.currentFrameNumber = this.sceneManager.maxFrames();
      this.currentFrameTime = this.sceneManager.endTime;
    }

    this.currentFrameDataValue = null;
    this.generateSceneUpdates();
  }

  public rewind() {
    this.pause();
    this.currentFrameNumber = 0;
    this.currentFrameTime = 0;
    this.currentFrameDataValue = null;
    this.generateSceneUpdates();
    // Todo: Load frame data and run a render
  }

  private generateSceneUpdates() {
    this.startFrameGenWatch = Date.now();

    if (this.debugIsDebugMode) {
      this.debugAverageFPS = this.babylonRenderer.engine.getFps().toFixed(0);
      this.debugNumSceneMeshes = this.babylonRenderer.scene.meshes.length;
      this.debugFrameGenerateTime = Date.now() - this.startFrameGenWatch;
    }
    this.areSceneUpdates = true;
  }

  private addLightToRenderer(lightId, lightData) {
    const applyLightProp = (prop) => {
      const propValue = lightData[prop];
      if (propValue) this.syncLightValue(lightId, prop, propValue);
    };

    switch (lightData.type) {
      case 'directional': {
        const newLight = this.babylonRenderer.createDirectionalLight(lightId, lightData.direction);
        ['position', 'intensity', 'diffuse', 'specular', 'enabled'].forEach((prop) => {
          applyLightProp(prop);
        });
        if (boolHasValueAndTrue(lightData.generateShadows)) {
          if (lightData.shadowDarkness) {
            this.babylonRenderer.addShadowGenerator(newLight, lightData.shadowDarkness);
          } else {
            this.babylonRenderer.addShadowGenerator(newLight, 0.6);
          }
        }
        break;
      }
      case 'hemispheric': {
        this.babylonRenderer.createHemisphericLight(lightId, this.babylonRenderer.getVector(lightData.direction));
        ['position', 'intensity', 'diffuse', 'specular', 'enabled'].forEach((prop) => {
          applyLightProp(prop);
        });
        break;
      }
      case 'point': {
        const newLight = this.babylonRenderer.createPointLight(
          lightId,
          this.babylonRenderer.getVector(lightData.position),
        );
        ['direction', 'intensity', 'diffuse', 'specular', 'enabled'].forEach((prop) => {
          applyLightProp(prop);
        });
        if (boolHasValueAndTrue(lightData.generateShadows)) {
          if (lightData.shadowDarkness) {
            this.babylonRenderer.addShadowGenerator(newLight, lightData.shadowDarkness);
          } else {
            this.babylonRenderer.addShadowGenerator(newLight, 0.6);
          }
        }
        break;
      }
      case 'spot': {
        const newLight = this.babylonRenderer.createSpotLight(
          lightId,
          this.babylonRenderer.getVector(lightData.position),
          this.babylonRenderer.getVector(lightData.direction),
          lightData.angle,
          lightData.exponent,
        );
        ['intensity', 'diffuse', 'specular', 'enabled'].forEach((prop) => {
          applyLightProp(prop);
        });
        if (boolHasValueAndTrue(lightData.generateShadows)) {
          if (lightData.shadowDarkness) {
            this.babylonRenderer.addShadowGenerator(newLight, lightData.shadowDarkness);
          } else {
            this.babylonRenderer.addShadowGenerator(newLight, 0.6);
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  private addMaterialToRenderer(materialId, matData) {
    const applyMaterialProp = (prop) => {
      const propValue = matData[prop];
      if (propValue) this.materialFactoryService.setMaterialValue(materialId, prop, propValue);
    };

    switch (matData.type) {
      case 'standard': {
        this.babylonRenderer.createMaterial(materialId);
        [
          'diffuseColor',
          'specularColor',
          'ambientColor',
          'wireframe',
          'alpha',
          'diffuseTexture',
          'bumpTexture',
          'pointsCloud',
          'pointSize',
        ].forEach((prop) => applyMaterialProp(prop));
        break;
      }
      case 'grid': {
        this.babylonRenderer.createGridMaterial(materialId);
        ['gridRatio', 'mainColor', 'lineColor', 'alphaMode', 'alpha'].forEach((prop) => applyMaterialProp(prop));
        break;
      }
      case 'shadow-receiver': {
        this.babylonRenderer.createShadowOnlyMaterial(materialId);
        break;
      }
      case 'custom-shader': {
        this.babylonRenderer.createCustomShaderMaterial(materialId, matData.commonShaderName);
        break;
      }
      default: {
        break;
      }
    }
  }

  private addRobot(callsign, callsignSettings) {
    const objectId = `robot-${callsign}`;
    this.addMesh(objectId, { ...callsignSettings.robotMesh, displayName: objectId, callsign });

    // add any sensor info
    if (callsignSettings.sensors) {
      callsignSettings.sensors.forEach((sensor) => {
        this.addDefaultObject(sensor.type, objectId + '-' + sensor.type);
        sensor.bindings.forEach((b) => {
          this.updateBinding(
            new VizBinding(`${objectId}-${sensor.type}.${b.name}`, `/${callsign}${b.source}`, b.valueExpression, null),
          );
        });
      });
    }

    // add any bindings
    if (callsignSettings.bindings) {
      callsignSettings.bindings.forEach((binding) => {
        this.updateBinding(
          new VizBinding(`${objectId}.${binding.name}`, `/${callsign}${binding.source}`, binding.valueExpression, null),
        );
      });
    }
  }

  /** Adds the mesh to the Rocos operation.  Includes both Scene description and the live renderer */
  private addMesh(objectKey: string, meshData) {
    if (!meshData.ops) {
      meshData.ops = {
        ...defaultMeshOps,
        listChildren: false,
      };
    }

    const withNameOrDefault = {
      ...meshData,
    } as IMesh;
    withNameOrDefault.displayName = withNameOrDefault.displayName || withNameOrDefault.type;
    this.sceneManager.addMesh(objectKey, withNameOrDefault);
    this.meshFactoryService.addMeshToRenderer({
      meshId: objectKey,
      meshData,
    });
    this.rendererSelectionService.resetGizmoManager();
  }

  private getPayloadValueFromMesh(meshId: string, propertyId: string, oldPayloadValue: any): any {
    // if the property is rotation or position, oldPayloadValue needs to be a number
    if (!meshId || !propertyId || isNaN(oldPayloadValue)) return oldPayloadValue;
    let finalValue = parseFloat(oldPayloadValue);
    const meshProps = this.sceneManager.findMesh(meshId);
    if (!meshProps) return finalValue;

    try {
      const props = propertyId.split('.');
      const propName = props[0];
      const direction = 'xyz'.indexOf(props[1]);

      // eslint-disable-next-line no-eval
      finalValue += meshProps?.[propName]?.[direction] ? parseFloat(eval(meshProps[propName][direction] as string)) : 0;
    } catch (e) {
      console.warn(`Unable to parse bound expression value for meshId:'${meshId}' propertyId:'${propertyId}'`);
    }

    return finalValue;
  }

  private shouldProcessBoundMessage() {
    // TODO: decide if should process this frame based on the time interval for rendering, perhaps per subscription
    // check the binding (a new interval property perhaps), check when this binding was last rendered
    // then only render if that time + interval large enough
    return this.playing === 'playingLive';
  }

  private processSubscriptionMessage(msg: IRocosTelemetryMessage, boundSource: VizBoundSource) {
    const boundSourceKey = '/' + msg.callsign + msg.source;
    if (this.sceneManager && boundSource && msg) {
      const newUpdates = [];
      Object.keys(boundSource.bindings)
        .filter((bindingKey) => !boundSource.bindings[bindingKey].disabled)
        .forEach((bindingKey) => {
          const updates = this.generateBoundUpdate(
            bindingKey,
            boundSourceKey,
            boundSource.bindings[bindingKey].valueExpression,
            msg,
          );
          if (!updates) return;
          if (!newUpdates[updates.meshId]) newUpdates[updates.meshId] = {};

          const props = updates.property.split('.');
          if (!newUpdates[updates.meshId][props[0]]) newUpdates[updates.meshId][props[0]] = {};

          newUpdates[updates.meshId][props[0]][props[1]] = updates.value;
        });

      Object.keys(newUpdates).forEach((meshId) => {
        const mesh = this.babylonRenderer.getMesh(meshId);
        const meshData = this.sceneManager.findMesh(meshId);
        if (!mesh || !meshData) return;
        ['position', 'rotation', 'scaling'].forEach((prop) => {
          if (!newUpdates[meshId]?.[prop]) return;
          mesh[prop] = this.babylonRenderer.getVector([
            newUpdates[meshId][prop]?.x ?? mesh[prop].x ?? meshData[prop]?.[0] ?? 0,
            newUpdates[meshId][prop]?.y ?? mesh[prop].y ?? meshData[prop]?.[1] ?? 0,
            newUpdates[meshId][prop]?.z ?? mesh[prop].z ?? meshData[prop]?.[2] ?? 0,
          ]);
        });
      });
    }
  }
}
