export enum NotificationType {
  notification = 'NOTIFICATION',
  warning = 'WARNING',
  permanentInformation = 'PERMANENT',
  error = 'ERROR',
}

export interface INotification {
  id: string;
  page: string;
  message: string;
  title?: string;
  actionButtonLabel?: string;
  docLinkType: string;
  docLinkLabel: string;
  type: NotificationType;
  isRead: boolean;
}

export class Notification implements INotification {
  public inline: boolean;
  public id: string;
  public page: string;
  public message: string;
  public title?: string;
  public actionButtonLabel?: string = 'Got It';
  public docLinkType: string = 'docs';
  public docLinkLabel: string = 'More Information';
  public type: NotificationType = NotificationType.notification;
  private _isRead: boolean;

  public set isRead(isRead: boolean) {
    if (!this.isPermanent) {
      this._isRead = isRead;
    }
  }

  public get isRead(): boolean {
    return this._isRead;
  }

  public get isWarning(): boolean {
    return this.type === NotificationType.warning;
  }

  public get isError(): boolean {
    return this.type === NotificationType.error;
  }

  public get isPermanent(): boolean {
    return this.type === NotificationType.permanentInformation;
  }

  constructor(
    id?: string,
    page?: string,
    message?: string,
    isRead?: boolean,
    title?: string,
    actionButtonLabel?: string,
    docLinkType?: string,
    docLinkLabel?: string,
    type?: NotificationType,
    inline = false,
  ) {
    this.id = id;
    this.page = page;
    this.message = message;
    this._isRead = isRead;
    this.title = title;
    this.actionButtonLabel = actionButtonLabel;
    this.docLinkType = docLinkType;
    this.docLinkLabel = docLinkLabel;
    this.type = type;
    this.inline = inline;
  }

  public static fromModel(model: Notification): Notification {
    const notification = new Notification();

    notification.id = model.id;
    notification.page = model.page;
    notification.message = model.message;
    notification._isRead = model.isRead;
    notification.title = model.title;
    notification.actionButtonLabel = model.actionButtonLabel;
    notification.docLinkType = model.docLinkType;
    notification.docLinkLabel = model.docLinkLabel;
    notification.type = model.type ? model.type : notification.type;

    return notification;
  }
}
