export enum UITabLinks {
  Projects = 'projects',
  People = 'people',
  Invite = 'invite',
  Debug = 'debug',
  Details = 'details',
  History = 'history',
  General = 'general',
  Dashboard = 'dashboard',
  Members = 'members',
  Application = 'application',
  Config = 'config',
  Integrations = 'integrations',
  ExperimentalFeatures = 'experimental-features',
  DashboardV2 = 'dashboard-v2',
  Settings = 'settings',
  Events = 'events',
  Commands = 'commands-v2',
  Controls = 'controls',
  Buttons = 'buttons',
  Gamepads = 'gamepads',
  Physical = 'physical',
  StorageStreams = 'data-stream',
  LiveDataViewer = 'data-viewer',
  Files = 'files',
  RawCmd = 'raw-commands',
  Shell = 'shell',
  All = './',
  List = './',
  Virtual = 'virtual',
  Cloud = 'cloud',
  Provisioning = 'provisioning',
  Automate = 'automate',
  RobotMaps = 'maps',
}
