import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import type { Notification } from '../../models';
import { NotificationType } from '../../models';
import { NotificationService } from '../../services';

@Component({
  selector: 'app-notification-box',
  templateUrl: './notification-box.component.html',
  styleUrls: ['./notification-box.component.scss'],
})
export class NotificationBoxComponent implements OnInit {
  @Input()
  pageId: string;

  @Input()
  inlineActionButton: boolean = false;

  @Input()
  bottomMargin: boolean = true;

  @Input()
  topLeftRightMargin: boolean = false;

  @Input()
  stickyLeft: boolean = false;

  notifications: Notification[];

  notification: Notification;

  isNotificationClosing: boolean = false;

  public notificationType = NotificationType;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    this.loadNotifications();
  }

  onNotificationMessageGot() {
    if (this.notifications?.length > 0) {
      this.notifications.forEach((notification) => {
        this.notificationService.markNotificationAsRead(notification).pipe(first()).subscribe();
      });
    }

    this.isNotificationClosing = true;
  }

  loadNotifications() {
    this.notificationService.getUnreadNotificationsForPage(this.pageId).subscribe((list) => {
      this.notifications = list;

      if (this.notifications?.length > 0) {
        this.notification = this.notifications[0];
      }
    });
  }

  get isActionButtonVisible(): boolean {
    return !!this.notification.actionButtonLabel;
  }

  get isDocLinkVisible(): boolean {
    return !!this.notification.docLinkType;
  }
}
