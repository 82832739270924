import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper {
  @Input() buttonLabels: string[] = [];
  @Output() done = new EventEmitter<void>();

  beforeNext: (currentIndex: number) => Promise<boolean>;

  protected loading$ = new BehaviorSubject(false);

  goToStep(i: number) {
    this.selectedIndex = i;
  }

  nextButtonLabel() {
    if (this.buttonLabels.length && this.buttonLabels.length >= this.selectedIndex - 1)
      return this.buttonLabels[this.selectedIndex];

    switch (this.selectedIndex) {
      case 0:
        return 'Start';
      case this.steps.length - 1:
        return 'Finish and Exit';
      default:
        return 'Next';
    }
  }

  override next() {
    if (this.beforeNext === undefined) {
      super.next();
      return;
    }

    if (this.selected?.stepControl?.invalid) {
      this.selected?.stepControl?.markAllAsTouched();
      return;
    }

    this.loading$.next(true);
    this.beforeNext(this.selectedIndex)
      .then((canProceed) => {
        if (canProceed) super.next();
      })
      .finally(() => this.loading$.next(false));
  }

  finished() {
    this.done.emit();
  }
}
