import { OverlayModule } from '@angular/cdk/overlay';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { LoadingContentModule } from '@shared-modules/loading-content';
import { CourierWrapperModule } from '@shared/components/courier-wrapper/courier-wrapper.module';
import { FlowInputsComponent } from '@shared/components/flow-inputs/flow-inputs.component';
import { DirectivesModule } from '../directives';
import { MaterialModule } from '../material';
import { BagFileDropComponent } from './bag-file-drop';
import { CanDeactivateBaseComponent } from './can-deactivate-base';
import { RobotClockComponent } from './clocks/robot-clock/robot-clock.component';
import { UserClockComponent } from './clocks/user-clock/user-clock.component';
import { ColourPickerComponent } from './colour-picker';
import { CommandWithValueButtonComponent } from './command-with-value-button/command-with-value-button.component';
import { CopyCodeComponent } from './copy-code';
import { DashboardScaleButtonsComponent } from './dashboard-scale-buttons/dashboard-scale-buttons.component';
import { DDLoadingSpinnerComponent } from './dd-loading-spinner/dd-loading-spinner.component';
import { DevDebugDialogComponent } from './dev-debug-dialog';
import { ExpansionPanelComponent } from './expansion-panel';
import { ExternalLinkComponent } from './external-link';
import { ExternalLinkModule } from './external-link/external-link.module';
import { FileUploadDialogComponent } from './file-upload-dialog';
import { FullscreenControlsComponent } from './fullscreen-controls/fullscreen-controls.component';
import { IconComponent } from './icon/icon.component';
import { ImagesCarouselComponent } from './images-carousel/images-carousel.component';
import { JsonDataTableComponent } from './json-data-table/json-data-table.component';
import { LoadingButtonComponent } from './loading-button';
import { LoadingSpinnerComponent, LoadingSpinnerModule } from './loading-spinner';
import { NotificationBarComponent } from './notification-bar';
import { NotificationBoxComponent } from './notification-box';
import { ProjectPickerComponent } from './project-picker';
import { RobotSearchComponent } from './robot-search';
import { RobotStatusDotComponent } from './robot-status-dot';
import { ScaleWrapperComponent } from './scale-wrapper/scale-wrapper.component';
import { StatusDotComponent } from './status-dot';
import { StepperButtonComponent } from './stepper-button/stepper-button.component';
import { StepperComponent } from './stepper/stepper.component';
import { ChipComponent } from './ui';
import { UnsupportedBrowserComponent } from './unsupported-browser';
import { UserInitialsComponent } from './user-initials';
import { WidgetButtonGroupParamsComponent } from './widget-button-group-params';

const components = [
  BagFileDropComponent,
  CanDeactivateBaseComponent,
  ChipComponent,
  ColourPickerComponent,
  CommandWithValueButtonComponent,
  CopyCodeComponent,
  DDLoadingSpinnerComponent,
  DashboardScaleButtonsComponent,
  DevDebugDialogComponent,
  ExpansionPanelComponent,
  FileUploadDialogComponent,
  FlowInputsComponent,
  FullscreenControlsComponent,
  IconComponent,
  ImagesCarouselComponent,
  JsonDataTableComponent,
  JsonDataTableComponent,
  LoadingButtonComponent,
  NotificationBarComponent,
  NotificationBoxComponent,
  ProjectPickerComponent,
  RobotClockComponent,
  RobotSearchComponent,
  RobotStatusDotComponent,
  ScaleWrapperComponent,
  StatusDotComponent,
  StepperButtonComponent,
  StepperComponent,
  UnsupportedBrowserComponent,
  UserClockComponent,
  UserInitialsComponent,
  WidgetButtonGroupParamsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    RouterModule,
    MaterialModule,
    LoadingSpinnerModule,
    ExternalLinkModule,
    TranslocoModule,
    LoadingContentModule,
    OverlayModule,
    CdkStepperModule,
  ],
  declarations: [...components],
  exports: [...components, LoadingSpinnerComponent, ExternalLinkComponent, CourierWrapperModule],
  bootstrap: [UserInitialsComponent],
})
export class SharedComponentsModule {}
