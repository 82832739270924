import { Injectable } from '@angular/core';
import type { AssetSyncDefinitionsModel } from '@dronedeploy/rocos-js-sdk';
import { RocosSdkClientService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class AssetStorageService {
  public constructor(private sdk: RocosSdkClientService) {}

  public create(projectId: string, filePath: string, file: File): Promise<any> {
    return this.sdk.client.getAssetStorageService().create(projectId, filePath, file);
  }

  public update(projectId: string, filePath: string, file: File): Promise<any> {
    return this.sdk.client.getAssetStorageService().update(projectId, filePath, file);
  }

  public list(projectId: string, filePath?: string): Promise<any> {
    return this.sdk.client.getAssetStorageService().list(projectId, filePath);
  }

  public getAsset(projectId: string, filePath: string): Promise<Blob> {
    return this.sdk.client.getAssetStorageService().getAsset(projectId, filePath);
  }

  public setRobotSyncDefinition(
    projectId: string,
    callsign: string,
    definition: AssetSyncDefinitionsModel,
    syncNow = false,
  ): Promise<AssetSyncDefinitionsModel> {
    return this.sdk.client.getAssetStorageService().setRobotSyncDefinitions(projectId, callsign, definition, syncNow);
  }

  public getRobotSyncDefinitions(projectId: string, callsign: string): Promise<AssetSyncDefinitionsModel> {
    return this.sdk.client.getAssetStorageService().getRobotSyncDefinitions(projectId, callsign);
  }
}
