import { Component, Input } from '@angular/core';
import { environment } from '@env/environment';

export type LinkType = 'stroked-button' | 'link' | 'primary-button' | 'button';
export type ExternalTargetType =
  | 'docs'
  | 'docsStreamingData'
  | 'docsConnectRobot'
  | 'docsDashboard'
  | 'docsCommands'
  | 'docsControls'
  | 'ddOrganizationUsers';

@Component({
  selector: 'app-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss'],
})
export class ExternalLinkComponent {
  @Input() linkType: LinkType = 'link';
  @Input() targetType: string = 'docs';
  @Input() targetLink: string;

  public get link(): string {
    if (this.targetLink) {
      return this.targetLink;
    }

    switch (this.targetType as ExternalTargetType) {
      case 'docs':
        return environment.links.docs;
      case 'docsStreamingData':
        return environment.links.docsStreamingData;
      case 'docsConnectRobot':
        return environment.links.docsConnectRobot;
      case 'docsDashboard':
        return environment.links.docsDashboard;
      case 'docsCommands':
        return environment.links.docsCommands;
      case 'docsControls':
        return environment.links.docsControls;
      default:
        return '';
    }
  }
}
