import type { OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormUtils } from '@shared/utils/form-utils';
import type { Observable } from 'rxjs';
import { of, Subject } from 'rxjs';
import { debounceTime, filter, first, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import type { IsLoading } from '../../interfaces';
import type { Robot } from '../../models';
import { RobotService } from '../../services';
import type { Heartbeat } from '../../models/robot/heartbeat';

const defaultHint = 'No robot selected.';

@Component({
  selector: 'app-robot-search',
  templateUrl: './robot-search.component.html',
  styleUrls: ['./robot-search.component.scss'],
})
export class RobotSearchComponent implements OnChanges, OnInit, IsLoading, OnDestroy {
  @Input() public projectId: string;
  @Input() public placeholder: string = 'Robot Call Sign';
  @Input() public notFoundTooltip: string = 'Robot not found';
  @Input() public robotRequired: boolean = true;
  @Input() public hint: string = defaultHint;
  @Input() public filledRobots: Robot[] = [];
  @Input() public defaultCallsign: string;
  @Input() public robotDefinition: string;
  @Input() public control: UntypedFormControl;
  @Input() public includeStatus = false;

  @Output() public callsignSelected = new EventEmitter<string>();
  @Output() public robotCountUpdated = new EventEmitter<number>();

  // Necessary if status included
  public robotHeartbeat: Heartbeat;
  public form: UntypedFormGroup;
  public isLoading: boolean = false;
  public robots$: Observable<Robot[]>;
  public focused = false;

  private destroy$ = new Subject<void>();

  // Component either works with a provided control or standalone
  public get callsign(): UntypedFormControl {
    return this.control ?? (FormUtils.getControl(this.form, 'callsign') as UntypedFormControl);
  }

  public constructor(private robotService: RobotService) {}

  public ngOnInit(): void {
    this.createForm();

    if (this.includeStatus) {
      this.statusHandler();
    }

    this.setDefaultCallsign();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['projectId'] || changes['filledRobots'] || changes['robotDefinition']) {
      this.loadRobotsList();
    }

    // Set default callsign
    if (changes['defaultCallsign']) {
      this.setDefaultCallsign();
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public onOptionSelected(callsign: string): void {
    if (callsign) {
      this.callsignSelected.next(callsign);
      this.focused = false;
    }
  }

  /**
   * @description Only if status included
   */
  private statusHandler(): void {
    // Consume form-control values
    // Get robot's heartbeat
    // Update UI on consequence
    const obs$ = this.callsign.valueChanges.pipe(
      // Startwith helps trigger in case a formControl is provided pre-set
      startWith(this.callsign.value),
      filter((v) => !!v),
      takeUntil(this.destroy$),
      debounceTime(100),
      tap(() => {
        this.hint = 'Connecting';
        this.isLoading = true;
        this.robotHeartbeat = null;
      }),
      switchMap((callsign) => {
        return this.robotService.getHeartbeat(this.projectId, callsign).pipe(takeUntil(this.destroy$));
      }),
      tap(() => (this.isLoading = false)),
    ) as Observable<Heartbeat>;

    obs$.subscribe((heartbeat) => {
      this.robotHeartbeat = heartbeat;
      this.hint = defaultHint;
    });
  }

  private loadRobotsList() {
    if (this.filledRobots?.length) {
      // Use prefilled robots
      this.robots$ = of(this.filledRobots);
    } else if (this.robotDefinition?.length) {
      // Filter based on robotDefinition
      this.robots$ = this.robotService.list$(this.projectId).pipe(
        map((i) => {
          return i.filter((j) => j.robotDefinition === this.robotDefinition);
        }),
      );
    } else {
      // Show all robots
      this.robots$ = this.robotService.list$(this.projectId);
    }

    this.robots$.pipe(first()).subscribe((x) => {
      if (x) {
        this.robotCountUpdated.emit(x.length);
      }
    });
  }

  private createForm() {
    this.form = new UntypedFormGroup({
      callsign: new UntypedFormControl(null, this.robotRequired ? [Validators.required] : []),
    });
  }

  private setDefaultCallsign() {
    if (this.callsign && this.defaultCallsign) {
      this.callsign.setValue(this.defaultCallsign);
    }
  }
}
