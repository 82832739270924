import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type {
  DefaultObjectTypesWithRoot,
  RendererDefaultObjectTypes,
} from '@shared/services/threeD/primitives/objectPrimitives';
import { allObjectTypes } from '@shared/services/threeD/primitives/objectPrimitives';
import type { IMesh } from '@shared/services/threeD/primitives/visualizer/interface/IMesh';

export type EditorPanelTypes =
  | 'parent'
  | 'properties'
  | 'bindPosition'
  | 'bindRotationEuler'
  | 'bindRotationQuaternion'
  | 'bindAdvanced'
  | 'geoJSON'
  | 'geoTransform'
  | 'lsTransform'
  | 'robotMap'
  | 'staticGeoJSON'
  | 'file'
  | 'pointcloud'
  | 'tiles'
  | 'raster'
  | 'controlPointGroup'
  | 'controlPoint'
  | 'cameraFrustum'
  | 'overlays'
  | 'basemap';

export type EditorPanelPropertiesType =
  | 'name'
  | 'position'
  | 'rotation'
  | 'scaling'
  | 'material'
  | 'gazebo-world'
  | 'dimensions';

export type ParentPanelConfig = boolean | DefaultObjectTypesWithRoot[];

export type EditorPanelProperties = Partial<Record<EditorPanelPropertiesType, boolean>>;

export interface EditorPanelTypeSettings {
  parent?: ParentPanelConfig;
  properties?: boolean | EditorPanelProperties;
  bindPosition?: boolean;
  bindRotationEuler?: boolean;
  bindRotationQuaternion?: boolean;
  bindAdvanced?: boolean;

  geoJSON?: boolean;
  geoTransform?: boolean;
  lsTransform?: boolean;
  pointcloud?: boolean;
  file?: boolean;
  tiles?: boolean;
  raster?: boolean;
  controlPointGroup?: boolean;
  controlPoint?: boolean;
  overlays?: boolean;
  cameraFrustum?: boolean;
  robotMap?: boolean;
  staticGeoJSON?: boolean;
}

export type EditorPanelType = boolean | EditorPanelTypeSettings | undefined;

const parentPrimitives: DefaultObjectTypesWithRoot[] = ['box', 'torus', 'sphere', 'plane', 'line', 'polyhedron'];
const parentTransforms: DefaultObjectTypesWithRoot[] = ['transform', 'geoTransform', 'lsTransform'];
const parentComplexTypes: DefaultObjectTypesWithRoot[] = ['file-any', 'file-any-child', 'spot-robot'];

export const parentDefault: DefaultObjectTypesWithRoot[] = [
  ...parentPrimitives,
  ...parentTransforms,
  ...parentComplexTypes,
];

export const defaultPanels: EditorPanelTypeSettings = {
  // Generic Panels
  parent: parentDefault,
  properties: {
    name: true,
    position: true,
    rotation: true,
    scaling: true,
    material: true,
    'gazebo-world': false,
    dimensions: false,
  },
  bindPosition: true,
  bindRotationEuler: true,
  bindRotationQuaternion: true,
  bindAdvanced: false,
  // Custom Panels
  geoJSON: false,
  geoTransform: false,
  lsTransform: false,
  pointcloud: false,
  file: false,
  tiles: false,
  raster: false,
  robotMap: false,
  staticGeoJSON: false,
  controlPointGroup: false,
  controlPoint: false,
  overlays: false,
};

// usage: <div *ngIf="currentMesh | editorPanel:'properties':'position'">

@Pipe({ name: 'editorPanel' })
export class EditorPanelPipe implements PipeTransform {
  transform(mesh: IMesh, panel: EditorPanelTypes, property?: EditorPanelPropertiesType): boolean {
    const type = mesh?.type;

    let editorPanels: EditorPanelType = allObjectTypes[type as RendererDefaultObjectTypes]?.editorPanels;
    if (typeof editorPanels === 'boolean') return editorPanels;
    if (editorPanels === undefined) editorPanels = defaultPanels;

    const editorPanel = editorPanels[panel];
    if (property && typeof editorPanel === 'object') {
      const subPanel = editorPanel[property];
      if (subPanel === undefined) return !!defaultPanels[property];
      if (typeof subPanel === 'boolean') return subPanel;
      return !!subPanel[property];
    }
    return !!editorPanel;
  }
}
