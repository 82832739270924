import { Injectable } from '@angular/core';
import { Notification, NotificationType } from '../../models';
import { Observable } from 'rxjs';

export const NOTIFICATION_PREFIX_KEY = 'NOTIFICATION-';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly _mockNotifications = [
    new Notification(
      'view-docs-top-command-list',
      'command-list',
      "This is our new version of Commands. They're easier to setup, and they can be triggered via events or " +
        "through our API. Any pre-existing commands can be found under the new 'Controls' tab.",
      false,
      'Where are my commands?',
      'Got It',
      'docsCommands',
      'More Information',
    ),
    new Notification(
      'view-docs-top-robot-list',
      'robot-list',
      'The latest information about adding robots to DroneDeploy Robotics is always available via our ' +
        'online documentation.',
      false,
      'Adding Robots to DroneDeploy Robotics',
      'Got It',
      'docsConnectRobot',
      'View Documentation',
    ),
    new Notification(
      'view-docs-top-robot-definition-pat',
      'robot-definition-pat',

      'Use a token to easily script the provisioning of your robots. ' +
        'They will work for 90 days, or until deleted or used.',
      false,
      'Provisioning Tokens',
      'Got It',
      'docsConnectRobot',
      'More Information',
    ),
    new Notification(
      'warning-gamepad-tab',
      'gamepad-tester',
      'If you have a gamepad connected and its actions are configured below, ' +
        'commands will be sent to the connected robot when the gamepad buttons are pressed.',
      false,
      'Caution when a gamepad is connected',
      '',
      '',
      '',
      NotificationType.warning,
    ),
    new Notification(
      'warning-one-gamepad-only',
      'one-gamepad-only',
      '',
      false,
      'There is already a Gamepad Widget on this dashboard. This widget is limited to 1 per dashboard.',
      '',
      '',
      '',
      NotificationType.warning,
    ),
    new Notification(
      'warning-control-tab',
      'control-list',

      'Controls are the original version of Commands. Although Commands are now the recommended ' +
        'way to remotely execute commands on a robot, Controls are still useful for some API and LAN use ' +
        'cases and are still supported for those scenarios. For more information ' +
        "contact <a href='mailto: support@rocos.io'>support@rocos.io<a/>.",
      false,
      'Controls are deprecated in the portal',
      '',
      '',
      '',
      NotificationType.warning,
    ),
    new Notification(
      'warning-storage-invalid-date',
      'storage-explorer',

      "Some stored telemetry has 'dateCreated' values set to future times, and so won't display in this chart. " +
        'This may be due to the inaccuracy of one or more robot clocks. For more information, visit this page: ' +
        "<a href='https://docs-automate.dronedeploy.com/robotics-toolkit/getting-started/on-robot-agent/clock-synchronisation' target='_blank'>https://docs-automate.dronedeploy.com/robotics-toolkit/getting-started/on-robot-agent/clock-synchronisation</a>",
      false,
      'Future dates detected',
      '',
      '',
      '',
      NotificationType.warning,
    ),
    new Notification(
      'unsupported-browser',
      'unsupported-browser',
      'This browser does not support certain features, and you may encounter a significant loss of ' +
        'functionality.<br>For the best experience, we recommend using <a href="https://www.google.com/chrome/">Google Chrome</a> on a desktop device.',
      false,
      'Your Current Browser Is Unsupported',
      'Close',
      '',
      '',
      NotificationType.error,
      false,
    ),
  ];

  public getUnreadNotificationsForPage(pageName: string): Observable<Notification[]> {
    const list = this.getMockData();

    return new Observable((observer) => {
      const filteredList = list.filter((item) => {
        return item.isRead === false && item.page === pageName;
      });
      observer.next(filteredList);
      observer.complete();
    });
  }

  public markNotificationAsRead(notification: Notification): Observable<void> {
    notification.isRead = true;

    const id = this.getMockStorageId(notification.id);
    localStorage.setItem(id, JSON.stringify(notification));

    return new Observable((observer) => {
      observer.next();
      observer.complete();
    });
  }

  private getMockData(): Notification[] {
    const results = [];
    const list = this._mockNotifications;
    list.forEach((item) => {
      // Reset to unread
      item.isRead = false;
      const json = localStorage.getItem(this.getMockStorageId(item.id));

      if (json) {
        results.push(Notification.fromModel(JSON.parse(json)));
      } else {
        results.push(item);
      }
    });

    return results;
  }

  private getMockStorageId(id: string): string {
    return `${NOTIFICATION_PREFIX_KEY}${id}`;
  }
}
