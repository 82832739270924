import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import type { Observable } from 'rxjs';
import { ConfirmDialogComponent } from './confirm-dialog';
import type { ConfirmActionType } from './confirm-action-type';
import { TranslocoService } from '@ngneat/transloco';
import type { UnpublishedChangesDialogData, UnpublishedChangesResult } from './unpublished-changes-dialog';
import { UnpublishedChangesDialogComponent } from './unpublished-changes-dialog';
import type { ComponentType } from '@angular/cdk/portal';
import { lastValueFrom } from 'rxjs';

const DEFAULT_CONFIRM_TEXT_KEY = 'common.confirm';
const DEFAULT_DELETE_TEXT_KEY = 'common.delete';
const DEFAULT_DELETE_CANCEL_TEXT_KEY = 'common.cancel';
const DEFAULT_DELETE_PUBLISH_TEXT_KEY = 'common.publish';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog, private translocoService: TranslocoService) {}

  public deleteConfirm(
    title: string,
    message: string,
    confirmText?: string,
    confirmWarningMessage?: string,
  ): Observable<boolean> {
    return this.confirm(
      title,
      message,
      this.translocoService.translate(DEFAULT_DELETE_TEXT_KEY),
      this.translocoService.translate(DEFAULT_DELETE_CANCEL_TEXT_KEY),
      'delete',
      confirmText,
      confirmWarningMessage,
    );
  }

  public publishConfirm(title: string, message: string): Observable<boolean> {
    return this.confirm(
      title,
      message,
      this.translocoService.translate(DEFAULT_DELETE_PUBLISH_TEXT_KEY),
      this.translocoService.translate(DEFAULT_DELETE_CANCEL_TEXT_KEY),
      'warning',
    );
  }

  public confirm(
    title: string,
    message: string,
    actionButtonText: string = this.translocoService.translate(DEFAULT_CONFIRM_TEXT_KEY),
    cancelButtonText: string = this.translocoService.translate(DEFAULT_DELETE_CANCEL_TEXT_KEY),
    actionType: ConfirmActionType = 'confirm',
    confirmText?: string,
    confirmWarningMessage?: string,
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title,
        message,
        actionButtonText,
        cancelButtonText,
        actionType,
        confirmText,
        confirmWarningMessage,
      },
    });

    return dialogRef.afterClosed();
  }

  public confirmUnpublishedChanges(options?: UnpublishedChangesDialogData): Promise<UnpublishedChangesResult> {
    const dialogRef = this.dialog.open(UnpublishedChangesDialogComponent, {
      data: options,
    });
    return lastValueFrom(dialogRef.afterClosed());
  }

  public openDialog<T, U>(dialogComponent: ComponentType<T>, data: U): Promise<any> {
    const dialogRef = this.dialog.open(dialogComponent, { data });
    return dialogRef.afterClosed().toPromise();
  }
}
