import type { AbstractMesh, Scene, StandardMaterial } from '@babylonjs/core';
import { Matrix, Mesh, MeshBuilder, Quaternion, Space, TransformNode, Vector3 } from '@babylonjs/core';
import type { RobotMapsService } from '@robot/shared/robot-maps.service';
import type { PrimitiveMetaData } from '../primitives/primitives';
import { parentDefault } from '@shared-modules/properties-editor-panel/pipes/editor-panel/editor-panel.pipe';
import { Voxels, VoxelsTiler } from './voxels';
import { Voxblox, type VoxbloxData } from './voxblox';
import { initMapMaterials, MapMaterial } from '../primitives/materialPrimitives';
import { MultiDirectedGraph } from 'graphology';
import { singleSource } from 'graphology-shortest-path';
import type { Subscription } from 'rxjs';

export const robotGraphMetaData: PrimitiveMetaData = {
  key: 'robotMapLive',
  label: 'Robot Map Live',
  icon: 'ri-3d-box',
  editorPanels: {
    parent: parentDefault,
    properties: {
      name: true,
      position: false,
      rotation: false,
      scaling: false,
      material: false,
    },
    bindPosition: false,
    bindRotationEuler: false,
    bindRotationQuaternion: false,
    bindAdvanced: true,
    robotMap: false,
  },
};

export interface GraphEvent {
  type: string;
  details?: {
    id?: string;
    node?: GraphNode;
    edge?: GraphEdge;
  };
}

export interface GraphNode {
  id: string;
  type: string;
  edges?: Record<string, any>;
  data?: Record<string, any>;
  externalData?: Record<string, any>;
}

export interface GraphEdge {
  id: string;
  from: string;
  to: string;
  type: string;
  data?: Record<string, any>;
  externalData?: Record<string, any>;
}

export interface NodeWrapper {
  node: GraphNode;
}

export interface EdgeWrapper {
  edge: GraphEdge;
}

const isNodeWrapper = (r: unknown): r is NodeWrapper => (r as NodeWrapper).node !== undefined;
const isEdgeWrapper = (r: unknown): r is EdgeWrapper => (r as EdgeWrapper).edge !== undefined;

export class RobotGraph extends Mesh {
  private meshes: Map<string, Mesh | TransformNode> = new Map<string, Mesh | TransformNode>();
  private graph: MultiDirectedGraph = new MultiDirectedGraph();
  private nodeRenderers: Record<string, (nodeId: string) => Mesh | TransformNode> = {};
  private edgeRenderers: Record<string, (edgeId: string) => Mesh> = {};
  private parentName: string;
  private mapSubscription: Subscription;

  constructor(
    name: string,
    private scene: Scene,
    private robotMapService: RobotMapsService,
    private projectId: string,
    private sourceKey: string,
    private debug: boolean = false,
  ) {
    super(name, scene);
    initMapMaterials(scene);
    this.initNodeRenderers();
    this.initEdgeRenderers();
  }

  public override setEnabled(value: boolean) {
    super.setEnabled(value);
    this.meshes.forEach((mesh) => mesh.setEnabled(value));
  }

  public override dispose(doNotRecurse?: boolean, disposeMaterialAndTextures?: boolean): void {
    this.clear();
    this.mapSubscription?.unsubscribe();
    super.dispose(doNotRecurse, disposeMaterialAndTextures);
  }

  public get nodeCount(): number {
    return this.graph.order;
  }

  public get edgeCount(): number {
    return this.graph.size;
  }

  public getMesh(meshName: string): AbstractMesh | TransformNode {
    const node = this.meshes.get(meshName);
    if (node) return node as AbstractMesh | TransformNode;
    return null;
  }

  public getGraphFromServiceCall(): void {
    try {
      const { callsign, source } = this.getServiceSourceFromBoundUpdate(this.sourceKey);
      if (!callsign || !source) return;

      this.mapSubscription?.unsubscribe();
      this.mapSubscription = this.robotMapService
        .callServiceStream(this.projectId, callsign, source)
        .subscribe((response) => {
          if (!response) return;
          if (isNodeWrapper(response)) {
            this.addOrUpdateNode(response.node);
          }
          if (isEdgeWrapper(response)) {
            this.addOrUpdateEdge(response.edge);
          }
          this.renderGraph();
        });
    } catch (error) {
      console.error('Error loading dynamic map', error);
    }
  }

  public async renderBoundUpdate(update: GraphEvent[]): Promise<void> {
    if (!this.isEnabled()) return;

    this.initParentMesh();
    const updateNodes: Record<string, GraphNode> = {};
    const updateEdges: Record<string, GraphEdge> = {};
    update.forEach((event) => {
      switch (event.type) {
        case 'maps.MapClearedEvent':
          this.clear();
          break;
        case 'maps.NodeDataChangedEvent':
        case 'maps.NodeAddedEvent':
          updateNodes[event.details.node.id] = event.details.node as GraphNode;
          break;
        case 'maps.EdgeDataChangedEvent':
        case 'maps.EdgeAddedEvent':
          updateEdges[event.details.edge.id] = event.details.edge as GraphEdge;
          break;
        case 'maps.NodeDeletedEvent':
          this.deleteNode(event.details.id);
          break;
        case 'maps.EdgeDeletedEvent':
          this.deleteEdge(event.details.id);
          break;
      }
    });
    this.addOrUpdateNodes(Object.values(updateNodes));
    this.addOrUpdateEdges(Object.values(updateEdges));
    this.renderGraph();
  }

  private initParentMesh(): void {
    if (!this.parent) return null;

    this.parentName = this.parent['displayName'];
    this.addOrUpdateNode({ id: this.parentName, type: 'FRAME' });
    this.meshes.set(this.parentName, this.parent as TransformNode | Mesh);
  }

  private initNodeRenderers(): void {
    this.nodeRenderers = {
      'FRAME': this.renderTransformNode.bind(this),
      'PANORAMA': (nodeId) => this.renderCylinder(nodeId, this.getMaterial(MapMaterial.WHITE), 1, 0.5),
      'bd.spot.waypoint.v1': (nodeId) => this.renderSphere(nodeId, this.getMaterial(MapMaterial.YELLOW), 0.2),
      'bd.spot.object.v1': (nodeId) => this.renderSphere(nodeId, this.getMaterial(MapMaterial.TEAL), 0.1),
      'polygon.node.v1': (nodeId) => this.renderSphere(nodeId, this.getMaterial(MapMaterial.RED), 0.1),
      'voxels.tiler.v1': this.renderVoxelsTiler.bind(this),
      'voxels.v1': this.renderVoxels.bind(this),
      'voxblox.v1': this.renderTransformNode.bind(this),
      'voxblox.block.v1': this.renderVoxblox.bind(this),
    };
  }

  private initEdgeRenderers(): void {
    this.edgeRenderers = {
      'TRANSFORM': this.renderTransform.bind(this),
      'bd.spot.traversable.v1': (edgeId) => this.renderTube(edgeId, this.getMaterial(MapMaterial.YELLOW), 0.05),
      'bd.spot.observation.v1': (edgeId) => this.renderTube(edgeId, this.getMaterial(MapMaterial.TEAL), 0.025),
      'polygon.edge.v1': (edgeId) => this.renderTube(edgeId, this.getMaterial(MapMaterial.RED), 0.025),
      'polygon.start.v1': null,
      'polygon.belongs.v1': null,
      'path.edge.v1': (edgeId) => this.renderTube(edgeId, this.getMaterial(MapMaterial.GREEN), 0.05),
      'path.start.v1': null,
      'path.belongs.v1': null,
      'contains.tile': () => null,
      'ros.pc2.observation.v1': () => null,
    };
  }

  private addOrUpdateNode(node: GraphNode): void {
    if (this.graph.hasNode(node.id)) {
      this.graph.replaceNodeAttributes(node.id, { type: node.type, data: node.data, dirty: true });
      return;
    }
    try {
      this.graph.addNode(node.id, { type: node.type, data: node.data, dirty: true });
    } catch (error) {
      console.error('Error adding node:', error);
    }
  }

  private addOrUpdateEdge(edge: GraphEdge): void {
    if (this.graph.hasEdge(edge.id)) {
      this.graph.setEdgeAttribute(edge.id, 'data', edge.data);
      this.graph.setEdgeAttribute(edge.id, 'dirty', true);
      return;
    }
    if (!this.graph.hasNode(edge.from)) {
      this.addOrUpdateNode({ id: edge.from, type: undefined, edges: new Map([[edge.id, {}]]) });
    }
    if (!this.graph.hasNode(edge.to)) {
      this.addOrUpdateNode({ id: edge.to, type: undefined, edges: new Map([[edge.id, {}]]) });
    }
    try {
      this.graph.addDirectedEdgeWithKey(edge.id, edge.from, edge.to, {
        type: edge.type,
        from: edge.from,
        to: edge.to,
        data: edge.data,
        dirty: true,
      });
    } catch (error) {
      console.error('Error adding edge:', error);
    }
  }

  private addOrUpdateNodes(nodes: GraphNode[]): void {
    nodes.forEach((node) => this.addOrUpdateNode(node));
  }

  private deleteNode(nodeId: string): void {
    this.deleteMesh(nodeId);
    if (!this.graph.hasNode(nodeId)) return;
    this.graph.dropNode(nodeId);
  }

  private addOrUpdateEdges(edges: GraphEdge[]): void {
    edges.forEach((edge) => this.addOrUpdateEdge(edge));
  }

  private deleteEdge(edgeId: string): void {
    this.deleteMesh(edgeId);
    if (!this.graph.hasEdge(edgeId)) return;
    this.graph.dropEdge(edgeId);
  }

  private renderGraph(): void {
    this.initParentMesh();
    const startNodeId = this.parentName;
    if (!startNodeId) return;

    if (!this.graph.hasNode(startNodeId)) return;

    const paths = singleSource(this.graph, startNodeId);
    Object.values(paths).forEach((path) => {
      if (path.length === 1) return;
      for (let i = 1; i < path.length; i++) {
        const fromNode = path[i - 1];
        const toNode = path[i];
        this.graph.forEachDirectedEdge(fromNode, toNode, (edgeId, attr) => {
          if (attr['type'] === 'TRANSFORM') {
            this.renderNode(toNode);
            this.renderEdge(edgeId);
          }
        });
      }
    });
    this.graph.forEachEdge((edgeId, attr) => {
      if (attr['type'] !== 'TRANSFORM') this.renderEdge(edgeId);
    });
  }

  private getServiceSourceFromBoundUpdate(sourceKey: string): { callsign: string; source: string } {
    if (!sourceKey) return { callsign: null, source: null };

    const callsign = sourceKey.trim().split('/')[1];
    const source = '/' + sourceKey.trim().split('?')[0].split('/').slice(2).join('/') + '/get';
    return { callsign, source };
  }

  private clear(): void {
    const oldGraph = this.graph;
    const oldMeshes = new Map(this.meshes);
    this.graph = new MultiDirectedGraph();
    this.meshes = new Map<string, Mesh | TransformNode>();
    oldGraph.clear();
    oldMeshes.forEach((mesh, meshName) => {
      if (meshName !== this.parentName) mesh.dispose();
    });
  }

  private deleteMesh(meshName: string): void {
    const mesh = this.meshes.get(meshName);
    if (mesh) mesh.dispose();
    this.meshes.delete(meshName);
  }

  private getMaterial(materialName: string): StandardMaterial {
    return this.scene.getMaterialByName(materialName) as StandardMaterial;
  }

  private renderNode(nodeId: string): void {
    if (!this.graph.getNodeAttribute(nodeId, 'dirty')) return;

    const nodeType = this.graph.getNodeAttribute(nodeId, 'type');
    if (nodeType === undefined) return;

    let nodeMesh: Mesh | TransformNode;
    const renderFunction = this.nodeRenderers[nodeType];
    if (renderFunction) {
      nodeMesh = renderFunction(nodeId);
    } else {
      console.warn('Unknown node type:', nodeType, nodeId);
    }
    this.graph.setNodeAttribute(nodeId, 'dirty', false);
    if (!nodeMesh) return;

    this.meshes.set(nodeId, nodeMesh);
    nodeMesh.setEnabled(this.isEnabled());
    nodeMesh.computeWorldMatrix(true);
  }

  private renderTransformNode(nodeId: string): TransformNode {
    return (this.getMesh(nodeId) as TransformNode) || new TransformNode(nodeId, this.scene);
  }

  private renderCylinder(nodeId: string, material: StandardMaterial, diameter: number, height: number): Mesh {
    if (this.getMesh(nodeId)) return this.getMesh(nodeId) as Mesh;

    const cylinder = MeshBuilder.CreateCylinder(nodeId, { diameter, height }, this.scene);
    cylinder.rotate(new Vector3(1, 0, 0), Math.PI / 2, Space.LOCAL);
    cylinder.material = material;
    return cylinder;
  }

  private renderSphere(nodeId: string, material: StandardMaterial, diameter: number): Mesh {
    if (this.getMesh(nodeId)) return this.getMesh(nodeId) as Mesh;

    const sphere = MeshBuilder.CreateSphere(nodeId, { diameter, segments: 8 }, this.scene);
    sphere.material = material;
    return sphere;
  }

  private renderVoxelsTiler(nodeId: string): VoxelsTiler {
    return (this.getMesh(nodeId) as VoxelsTiler) || new VoxelsTiler(nodeId, this.scene);
  }

  private renderVoxels(nodeId: string): AbstractMesh | TransformNode {
    const voxelsMesh = this.getMesh(nodeId) || new Voxels(nodeId, this.scene);
    const nodeData = this.graph.getNodeAttribute(nodeId, 'data');
    const voxelData = nodeData['voxelData'];
    if (!voxelData || !(voxelsMesh instanceof Voxels)) {
      return voxelsMesh;
    }
    voxelsMesh.renderBoundUpdate(voxelData);
    this.graph.setNodeAttribute(nodeId, 'data', { voxelData: null });
    return voxelsMesh;
  }

  private renderVoxblox(nodeId: string): Mesh {
    const voxbloxMesh = (this.getMesh(nodeId) as Voxblox) || new Voxblox(nodeId, this.scene);
    const nodeData = this.graph.getNodeAttribute(nodeId, 'data');
    if (!nodeData || !(voxbloxMesh instanceof Voxblox)) {
      return voxbloxMesh;
    }
    voxbloxMesh.renderBoundUpdate(nodeData as VoxbloxData);
    this.graph.setNodeAttribute(nodeId, 'data', { voxelIndices: [], voxelColors: [] });
    return voxbloxMesh;
  }

  private renderEdge(edgeId: string): void {
    if (!this.graph.getEdgeAttribute(edgeId, 'dirty')) return;

    const edgeType = this.graph.getEdgeAttribute(edgeId, 'type');
    let edgeMesh: Mesh;
    const renderFunction = this.edgeRenderers[edgeType];
    if (renderFunction) {
      edgeMesh = renderFunction(edgeId);
    } else {
      console.warn('Unknown edge type:', edgeType, edgeId);
    }
    this.graph.setEdgeAttribute(edgeId, 'dirty', false);
    if (!edgeMesh) return;

    this.meshes.set(edgeId, edgeMesh);
    edgeMesh.setEnabled(this.isEnabled());
  }

  private getNodeMeshesForEdge(
    edgeId: string,
    fromId: string,
    toId: string,
  ): { fromNode: TransformNode | Mesh | null; toNode: TransformNode | Mesh | null } | null {
    this.deleteMesh(edgeId);
    const fromNodeMesh = this.getMesh(fromId);
    const toNodeMesh = this.getMesh(toId);
    if (!fromNodeMesh || !toNodeMesh) return null;
    fromNodeMesh?.computeWorldMatrix(true);
    toNodeMesh?.computeWorldMatrix(true);
    return { fromNode: fromNodeMesh, toNode: toNodeMesh };
  }

  private renderTransform(edgeId: string): Mesh {
    const edgeAttributes = this.graph.getEdgeAttributes(edgeId);
    const nodeMeshes = this.getNodeMeshesForEdge(edgeId, edgeAttributes['from'], edgeAttributes['to']);
    if (!nodeMeshes) return null;

    const { fromNode, toNode } = nodeMeshes;
    const transformMatrixData = edgeAttributes['data']['TransformMatrix'];
    if (!transformMatrixData) {
      console.error('Edge transform matrix not found:', edgeId);
      return null;
    }

    const transform = Matrix.FromArray(transformMatrixData).transpose();
    const scale = new Vector3();
    const rotation = new Quaternion();
    const translation = new Vector3();
    transform.decompose(scale, rotation, translation);
    toNode.position = translation;
    toNode.rotationQuaternion = rotation;
    toNode.scaling = scale;
    toNode.parent = fromNode;

    this.graph.forEachInEdge(toNode.name, (inEdgeId, attr) => {
      if (attr['type'] !== 'TRANSFORM') {
        this.graph.setEdgeAttribute(inEdgeId, 'dirty', true);
      }
    });

    if (!this.debug) return null;
    this.getMesh(edgeId)?.dispose();
    return this.renderDashedLine(edgeId, 1.0, 0.1);
  }

  private getEdgePath(
    edgeId: string,
  ): { fromNode: TransformNode | Mesh; toNode: TransformNode | Mesh; path: Vector3[] } | null {
    const edgeAttributes = this.graph.getEdgeAttributes(edgeId);
    const nodeMeshes = this.getNodeMeshesForEdge(edgeId, edgeAttributes['from'], edgeAttributes['to']);
    if (!nodeMeshes) return null;

    const { fromNode, toNode } = nodeMeshes;
    const path = [fromNode.getAbsolutePosition(), toNode.getAbsolutePosition()];
    if (path[0].equals(path[1])) return null;

    return { fromNode, toNode, path };
  }

  private renderDashedLine = (edgeId: string, dashSize: number, gapSize: number): Mesh | null => {
    const edgePath = this.getEdgePath(edgeId);
    if (!edgePath) return null;

    const { fromNode, path } = edgePath;
    const dashedLines = MeshBuilder.CreateDashedLines(edgeId, { points: path, dashSize, gapSize }, this.scene);
    dashedLines.setParent(fromNode);
    return dashedLines;
  };

  private renderTube = (edgeId: string, material: StandardMaterial, radius: number): Mesh | null => {
    const edgePath = this.getEdgePath(edgeId);
    if (!edgePath) return null;

    const { fromNode, path } = edgePath;
    const tube = MeshBuilder.CreateTube(edgeId, { path, radius, tessellation: 16 }, this.scene);
    tube.material = material;
    tube.setParent(fromNode);
    return tube;
  };
}
